import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const webMenuApi = createApi({
    reducerPath: 'webMenuApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['menu'],
    endpoints: (builder) => ({
        menu: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/menu/', method: 'GET' }
                } else {
                    return { url: `/menu/${arg}`, method: 'GET' }
                }
            },

        }),
        addMenu: builder.mutation({
            query: (page) => ({
                url: '/menu/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['menu']
        }),
        updateMenu: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/menu/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['menu']
        }),
        deleteMenu: builder.mutation({
            query: (id) => ({
                url: `/menu/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['menu']
        })
    })
});

export const { useMenuQuery, useAddMenuMutation, useUpdateMenuMutation, useDeleteMenuMutation } = webMenuApi;
