import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const skiperApi = createApi({
    reducerPath: 'skiperApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['skiper'],
    endpoints: (builder) => ({
        skiper: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/skiper/', method: 'GET' }
                } else {
                    return { url: `/skiper/${arg}`, method: 'GET' }
                }
            },

        }),
        addSkiper: builder.mutation({
            query: (page) => ({
                url: '/skiper/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['skiper']
        }),
        updateSkiper: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/skiper/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['skiper']
        }),
        deleteSkiper: builder.mutation({
            query: (id) => ({
                url: `/skiper/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['skiper']
        })
    })
});

export const { useSkiperQuery, useAddSkiperMutation, useUpdateSkiperMutation, useDeleteSkiperMutation } = skiperApi;
