import React from "react";
import DataTable from "../../components/pageParts/table";
import ActionCell from "../../components/pageParts/ActionCell";
import { Check, X } from "lucide-react";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../../components/ui/button";

import {
  useKarnetyQuery,
  useDeleteKarnetyMutation,
  useChangePaidMutation,
} from "../../services/karnetyApi";

import { useNavigate, useParams } from "react-router-dom";
import { wordWrap } from "voca";

const KarnetList = () => {
  const navigate = useNavigate();
  const title = undefined;

  const { data } = useKarnetyQuery(undefined);
  const [deleteKarnet, response] = useDeleteKarnetyMutation();
  const [changePaid] = useChangePaidMutation();

  const karnety = {
    KF: "Pełny",
    KD: "Debiut",
    KM: "Miesięczny",
    KK: "Kontynuacja",
  };

  const d = data?.map((item) => {
    return {
      ...item,
      karnetName: karnety[item.typ],
      objectId: item._id.$oid,
    };
  });

  const headerCells = [
    {
      accessorKey: "objectId",
      header: "objectId",
      visibilty: false,
      fancyName: "objectId",
    },
    {
      accessorKey: "karnetCode",
      header: "Kod",
      fancyName: "Kod",
    },

    {
      accessorKey: "karnetName",
      header: "Typ karnetu",
      fancyName: "Typ karnetu",
    },
    {
      accessorKey: "imie",
      header: "Imię",
      fancyName: "Imię",
    },
    {
      accessorKey: "nazwisko",
      header: "Nazwisko",
      fancyName: "Nazwisko",
    },

    {
      accessorKey: "paid",
      visibilty: false,
      facyName: "paid",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Zapłacono
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }: any) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              changePaid({
                id: row.original._id.$oid,
                paid: !row.original.paid,
              });
            }}
          >
            {row.original.paid ? (
              <Check className="text-green-500" />
            ) : (
              <X className="text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "block",
      visibilty: false,
      facyName: "Blokada",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Blokada
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }: any) => {
        console.log(row);
        return (
          <div>
            {row.original.block ? (
              <Check className="text-green-500" />
            ) : (
              <X className="text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      enableSorting: true,
      accessorKey: "zweryfikowany",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Zweryfikowany
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      facyName: "Zweryfikowany",
      visibilty: false,
      cell: ({ row }: any) => {
        console.log(row);
        return (
          <div>
            {row.original.zweryfikowany ? (
              <Check className="text-green-500" />
            ) : (
              <X className="text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      allwaysVisible: true,
      fancyName: "objectId",
      header: () => {
        <div className="flex justify-end">Akcje</div>;
      },

      cell: ({ row }: any) => {
        // console.log(row);
        return (
          <div className="justify-end flex text-[0.em]">
            <ActionCell
              deleteAction={deleteKarnet}
              editLink={"karnety/edit"}
              row={row}
            />
          </div>
        );
      },
    },
  ];

  const filters = [
    {
      field: "karnetCode",
      label: "Kod karnetu",
    },
    {
      field: "nazwisko",
      label: "Nazwisko",
    },
    {
      field: "paid",
      label: "Zapłacone",
      type: "bool",
    },
    {
      field: "block",
      label: "Zablokowany",
      type: "bool",
    },
    {
      field: "zweryfikowany",
      label: "Zweryfikowany",
      type: "bool",
    },
  ];

  return (
    <div>
      {d && (
        <DataTable
          columns={headerCells}
          data={d}
          label="Karnety"
          filters={filters}
          tableName="karnety"
        />
      )}
    </div>
  );
};
export default KarnetList;
