// import { SelectChangeEvent, Select, MenuItem, Box, FormHelperText } from '@mui/material';
import React from "react";

import { UiInputProps } from "../types/types";
import { usePgQuery } from "../../services/pageGeneratorApi";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import { Label } from "./label";

export const UiFragment = (props: UiInputProps & {action?:any}) => {
  const { data, refetch } = usePgQuery({ pageType: "fragmentStatyczny" });

  return (
    <div className="grid">
      <div className="flex gap-5">
        <Label>{props?.label}</Label>
        {props.action}
        </div>
      <Select
        defaultValue={props?.value?.replace("frag-", "")}
        onValueChange={(value) => {
          props?.onChange?.(`frag-${value}`);
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Wybierz opcje" />
        </SelectTrigger>
        <SelectContent>
          {data?.map((item: any, index: number) => {
            return (
              <SelectItem key={index.toString()} value={item._id.$oid}>
                {" "}
                {item.name}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );
};
