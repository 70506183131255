import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['profile'],
    endpoints: (builder) => ({
        profile: builder.query({
            query: (arg) => {
                if (arg == undefined) {
                    return {
                        url: '/profile/',
                        method: 'POST'
                    }
                } else {
                    return {
                        url: `/profile/foregin/${arg}`,
                        method: 'POST'
                    }
                }
            },


        }),
        addProfile: builder.mutation({
            query: (arg) => {
                return {
                    url: '/profile/add/',
                    body: arg,
                    method: 'POST'
                }
            }
        }),
        updateProfile: builder.mutation({
            query: (arg) => {
                return {
                    url: '/profile/update/',
                    body: arg,
                    method: 'POST'
                }
            }
        }),
        updateForeginProfile: builder.mutation({
            query: ({ id, rest }) => {
                return {
                    url: `/profile/update/foregin/${id}`,
                    body: rest,
                    method: 'POST'
                }
            }
        }),
        list: builder.query({
            query: () => {
                return {
                    url: '/profile/list/',
                    method: 'POST'
                }
            },
            providesTags: ['profile']


        }),
        delete: builder.mutation({
            query: (id) => {
                return {
                    url: `/profile/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['profile']
        }),


    })
});

export const { useProfileQuery, useAddProfileMutation, useUpdateProfileMutation, useUpdateForeginProfileMutation, useListQuery, useDeleteMutation } = profileApi;
