import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const statusesApi = createApi({
    reducerPath: 'statuses',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,

        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
            headers.set('Access-Control-Allow-Origin', '*')
            headers.set("Access-Control-Allow-Methods", "GET, PUT, POST, DELETE, OPTIONS")
            headers.set("WithCredentials", "true");

            headers.set("Access-Control-Allow-Credentials", "true")

            if (token) {

                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['statuses'],
    endpoints: (builder) => ({
        statuses: builder.query({
            query: () => {


                return { url: '/statuses/', method: 'GET' }

            },
            providesTags: ['statuses'],


        }),



    })
});

export const { useStatusesQuery } = statusesApi;
