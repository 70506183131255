import React from "react";
import DataTable from "../../components/pageParts/table";
import {
  useMenuQuery,
  useAddMenuMutation,
  useUpdateMenuMutation,
  useDeleteMenuMutation,
} from "../../services/menuApi";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../../components/ui/button";
import ActionCell from "../../components/pageParts/ActionCell";
const MenuList = () => {
  const title = undefined;

  const { data, refetch } = useMenuQuery(undefined);

  const [deleteMenu, delete_response] = useDeleteMenuMutation();
  const [updateMenu, update_response] = useUpdateMenuMutation();
  const [addMenu, add_response] = useAddMenuMutation();

  React.useEffect(() => {
    if (delete_response.isSuccess) {
      refetch();
    }
  }, [delete_response]);

  React.useEffect(() => {
    if (update_response.isSuccess) {
      refetch();
    }
  }, [update_response]);

  React.useEffect(() => {
    if (add_response.isSuccess) {
      refetch();
    }
  }, [add_response]);

  const filters = [
    {
      field: "name",
      label: "Nazwa",
    },
    {
      field: "slug",
      label: "Slug",
    },
  ];

  const headerCells = [
    {
      accessorKey: "objectId",
      header: "objectId",
      visibilty: false,
      fancyName: "objectId",
    },
    {
      accessorKey: "name",
      fancyName: "Nazwa",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Nazwa
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: "slug",
      fancyName: "Slug",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Slug
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },

    {
      accessorKey: "action",
      allwaysVisible: true,
      header: () => {
        <div className="flex justify-end">Akcje</div>;
      },

      cell: ({ row }: any) => {
        return (
          <div className="justify-end flex text-[0.em]">
            <ActionCell
              deleteAction={deleteMenu}
              editLink={"menu/edit"}
              row={row}
            />
          </div>
        );
      },
    },
  ];

  const d = data?.map((el: any) => {
    return {
      objectId: el._id.$oid,
      name: el.name,
      slug: el.slug,
    };
  });

  return (
    <div>
      {d && (
        <DataTable
          columns={headerCells}
          data={d}
          filters={filters}
          label={"Lista schematów"}
          tableName="generator"
        />
      )}
    </div>
  );
};
export default MenuList;
