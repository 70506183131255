import {
  Camera,
  Home,
  Plus,
  Image,
  ChefHat,
  Text,
  ShoppingBag,
  Cog,
  Folders,
  Percent,
  Phone,
  Mail,
} from "lucide-react";

export type IconType = {
  [key: string]: any; // Assuming your components are of type FC
};

const icons: IconType = {
  camera: Camera,
  home: Home,
  plus: Plus,
  Image: Image,
  ChefHat: ChefHat,
  Text: Text,
  ShoppingBag: ShoppingBag,
  Cog: Cog,
  Folders: Folders,
  Precent: Percent,
  Phone: Phone,
  Mail: Mail,
};

export { icons };
