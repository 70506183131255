import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const bookingOperationApi = createApi({
    reducerPath: 'bookingOperationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['bookingOperation','Booking'],
    endpoints: (builder) => ({
        changeTurnus: builder.mutation({
            query: (rest) => ({
                url: '/bookingOperation/changeTurnus/',
                method: 'POST',
                body: rest
            }),
            invalidatesTags: ['Booking']
        }),
        sendMailBookingOperation: builder.mutation({
            query: (page) => ({
                url: '/bookingOperation/sendMail/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['bookingOperation']
        }),

    })
});

export const { useChangeTurnusMutation, useSendMailBookingOperationMutation } = bookingOperationApi;
