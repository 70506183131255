import * as React from "react";

import { cn } from "../../lib/utils";
import { Label } from "./label";
import { Upload } from "lucide-react";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const UploadWithLabel = React.forwardRef<HTMLInputElement, InputProps & {label:string, action?: any}>(
  ({ className, type, ...props }, ref) => {
    const [visibility, setVisibility]=React.useState<boolean>(false);
    return (
      <div>
        <div className="flex gap-5">
          <Label htmlFor="email" >{props?.label}</Label>
          {props?.action}
        </div>
        <div className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
      <input
        type="file"
        className={cn(
          "flex-1 w-full h-full text-sm placeholder-text-muted-foreground bg-transparent outline-none",
          className
        )}
        ref={ref}
        {...props}
          />
          <div className="flex items-center" onClick={()=>{setVisibility(!visibility)}}>
          <Upload size={'1em'} />
            </div>
          </div>
          </div>
    );
  }
);
UploadWithLabel.displayName = "UploadWithLabel";

export { UploadWithLabel };
