import * as React from "react";

import { Label } from "./label";
import { Image } from "./image";
import { Button } from "./button";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "./card";
import { UploadCloud, Music, Video, ImageMinus } from "lucide-react";
import {
  DialogGallery,
  DialogGalleryContent,
  DialogGalleryDescription,
  DialogGalleryFooter,
  DialogGalleryHeader,
  DialogGalleryTitle,
} from "./dialogImageGallery";
import GalleryGrid from "../grids/galleryGrid";
import { setInit, setDirMode } from "../../store/gallery";
import { ImagePlaceHolder } from "./imagePlaceHolder";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const ImagePicker = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    label: string;
    onChange: (any) => any;
    chooseMultiple?: boolean;
    value?: any;
  } & {
    action?: any;
  }
>(({ className, type, ...props }, ref) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const dirmode = useSelector((state: any) => state.galleryState.dirMode);

  const selectedImages = useSelector((state: any) => state.galleryState.images);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setInit(props.value));
  }, [props.value]);

  const [currentImg, setCurrentImg] = React.useState<any>(props.value);

  const refgrid = React.useRef<any>(null);

  return (
    <div className="grid">
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props?.action}
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="circleOutline"
                onClick={() => {
                  props.onChange(null);
                }}
                size="icon"
              >
                <ImageMinus color="#25282c" size="16px" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Usuń</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <div
        onClick={() => {
          dispatch(setInit(props.value));
          setOpenDialog(true);
        }}
        className="cursor-pointer"
      >
        {props?.value &&
          ["jpg", "jpeg", "gif", "png", "webp", "jpeg"].includes(
            props?.value?.ext?.toLowerCase()
          ) && <Image {...props.value} w={"w-[100px]"} key={props.value._id} />}
        {props?.value &&
          ["mp3", "ogg", "mp4", "avi"].includes(
            props?.value?.ext?.toLowerCase()
          ) && (
            <ImagePlaceHolder
              {...props?.value}
              w={"w-[100px]"}
              filename={props?.value?.file_name.replace(
                "media/",
                "media/serveInFly/"
              )}
            >
              {["mp3", "ogg"].includes(props?.value?.ext.toLowerCase()) && (
                <Music size={"2em"} />
              )}
              {["mp4", "avi"].includes(props?.value?.ext.toLowerCase()) && (
                <Video size={"2em"} />
              )}
            </ImagePlaceHolder>
          )}
        {!props?.value && (
          <Card className="w-[120px] h-[120px]  rounded-sm flex items-center justify-center cursor-pointer">
            <UploadCloud color={"#454545"} size={"3em"} />
          </Card>
        )}
      </div>
      <DialogGallery
        open={openDialog}
        onOpenChange={() => {
          setOpenDialog(false);
        }}
      >
        <DialogGalleryContent
          setDirmode={() => {
            dispatch(setDirMode(!dirmode));
          }}
          dirmode={dirmode}
          multiCheck={false}
          checkAll={refgrid?.current?.checkAllImages}
          unCheckAll={refgrid?.current?.unCheckAllImages}
        >
          <DialogGalleryHeader>
            <DialogGalleryTitle>
              {dirmode === true ? "Wybierz katalog" : "Wybierz obrazek"}
            </DialogGalleryTitle>
          </DialogGalleryHeader>
          <DialogGalleryDescription>
            <GalleryGrid
              ref={refgrid}
              selectedImage={props?.value}
              chooseMultiple={false}
            />
          </DialogGalleryDescription>
          <DialogGalleryFooter>
            <Button
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              Zamknij
            </Button>
            <Button
              onClick={() => {
                setOpenDialog(false);

                props.onChange(null);
              }}
            >
              Czyść obrazek
            </Button>
            <Button
              onClick={() => {
                props.onChange(selectedImages);

                setOpenDialog(false);
              }}
            >
              Zapisz
            </Button>
          </DialogGalleryFooter>
        </DialogGalleryContent>
      </DialogGallery>
    </div>
  );
});
ImagePicker.displayName = "ImagePicker";

export { ImagePicker };
