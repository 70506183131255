export const metaTagsInitalState: { open: boolean, currentItem:any } = {
    open: false,

  currentItem: null
  };

export type MetaTagsActions = { type: 'OPEN',payload:any} | { type: 'CLOSE' } | { type: 'SET', payload: any }

export const metaTagsReducer = (state: typeof metaTagsInitalState, action: MetaTagsActions) => {

    switch (action.type) {
      case 'OPEN':
        return {
          ...state,
          open: true,
          currentItem: action.payload
        }
      case 'SET':
        return {
          ...state,

          currentItem:{...state.currentItem,alt:action?.payload?.alt || state.currentItem.alt ,title:action?.payload?.title || state.currentItem?.title}

        }

      case 'CLOSE':
        return {
          ...state,
          open: false,
          alt: null,
          title: null
        }
      default:
        return state
    }
  }
