import * as React from "react";

import { Label } from "./label";
import ImageComp from "./image";
import { Button } from "./button";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "./card";
import { UploadCloud, ImageMinus } from "lucide-react";
import { Progress } from "../../components/ui/progress";
import { chunk } from "lodash";
import {
  DialogGallery,
  DialogGalleryContent,
  DialogGalleryDescription,
  DialogGalleryFooter,
  DialogGalleryHeader,
  DialogGalleryTitle,
} from "./dialogImageGallery";
import GalleryGrid from "../grids/galleryGrid";
import { setInit, setDirMode } from "../../store/gallery";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../../components/ui/context-menu";
import { cloneDeep } from "lodash";
import { Suspense } from "react";
import upload from "../../services/uploadApi";
import { url } from "../../../src/config";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Loading = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
    </div>
  );
};

const GalleryPicker = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    label: string;
    onChange: (any) => any;
    chooseMultiple?: boolean;
    value?: any;
    fixedDirectory?: string;
    oid?: string;
  } & { action?: any }
>(({ className, type, ...props }, ref) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const dirmode = useSelector((state: any) => state.galleryState.dirMode);
  const selectedImages = useSelector((state: any) => state.galleryState.images);
  const currentDir = useSelector((state: any) => state.galleryState.currentDir);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const uploadRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    dispatch(setInit(props.value));
    console.log(props.value);
    cacheImages(props.value);
  }, [props.value, dispatch]);

  const fixDir = props.fixedDirectory?.includes("$id")
    ? props.fixedDirectory.replace("$id", props?.oid || "")
    : props.fixedDirectory;

  const cacheImages = async (images: any) => {
    if (images === null || images === undefined || images.length === 0) {
      setIsLoading(false);
    } else {
      const promises = await images.map((item: any) => {
        return new Promise<void>((resolve, reject) => {
          const img = new Image();
          console.log(
            `${url}/media/serveInFly/${item._id}?w=300&h=300&ext=webp&q=90`
          );
          img.src = `${url}/media/serveInFly/${item._id}?w=300&h=300&ext=webp&q=90`;
          img.onload = () => {
            resolve();
          };
          img.onerror = () => {};
        });
      });
      await Promise.all(promises);
      setIsLoading(false);
    }
  };

  const refgrid = React.useRef<any>(null);
  const dragItem = React.useRef<number>();
  const dragOver = React.useRef<number>();

  const dragStart = (e: any, index: number) => {
    dispatch({ type: "hideOption" });
    dragItem.current = index;
  };
  const dragOverFn = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any, index: number) => {
    e.preventDefault();
    dragOver.current = index;
  };

  const dragDrop = (e: any) => {
    if (
      dragItem.current !== undefined &&
      dragOver.current !== undefined &&
      props.value?.length > 1
    ) {
      const state = cloneDeep(props.value);

      const current = state[dragItem.current];
      const over = state[dragOver.current];

      state[dragItem.current] = over;
      state[dragOver.current] = current;

      props.onChange(state);
    }
  };

  //Dobra, to jest głupie ale działa. W dialog tylko za pierwszym razem dostaje się referencje do gridu. pozniej jest undefined.
  const [imperHandles, setImperHandlers] = React.useState<any>(null);

  React.useEffect(() => {
    setImperHandlers({
      checkAll: refgrid?.current?.checkAllImages,
      unCheckAll: refgrid?.current?.unCheckAllImages,
    });
  }, [openDialog]);

  const handleUpload = (event: any) => {
    uploadFiles(event?.dataTransfer?.files || []);
  };

  const [progress, setProgress] = React.useState(0);
  const [showProgressBar, setShowProgressBar] = React.useState(false);

  const [uploadedImages, setUploadedImages] = React.useState<any>([]);

  const uploadFiles = (files: any) => {
    setShowProgressBar(true);

    if (files?.length > 0) {
      const parts = 100 / files.length;
      const files_c = Array.from(files);

      const files_chunks = chunk(Array.from(files), 5);

      files_chunks.reduce((prevPromise, files_chunk, i) => {
        return prevPromise.then(() => {
          const promises = files_chunk.map((file: any) => {
            // Explicitly type the file argument as Blob
            const formData = new FormData();
            formData.append("directory", fixDir || "...");
            formData.append("file", file);

            return upload(formData);
          });

          return Promise.all(promises).then((resp: any) => {
            const imgs = resp.map((item: any) => {
              return item.img;
            });

            setUploadedImages([...uploadedImages, ...imgs]);

            const prog = (i + 1) * parts * 5;
            setProgress(prog > 100 ? 100 : prog);
            if (prog >= 100) {
              setShowProgressBar(false);
            }
          });
        });
      }, Promise.resolve());
    }
  };

  React.useEffect(() => {
    if (uploadedImages.length > 0) {
      const state = cloneDeep(props.value);
      const newState = [...state, ...uploadedImages];
      props.onChange(newState);
      setUploadedImages([]);
    }
  }, [uploadedImages]);

  return (
    <div className="grid">
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props?.action}
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="circleOutline"
                onClick={() => {
                  props.onChange(null);
                }}
                size="icon"
              >
                <ImageMinus color="#25282c" size="16px" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Usuń</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <div className="w-full flex flex-wrap items-center gap-2">
        {isLoading && (
          <div className="w-[180px] h-[180px] ml-[10px] rounded-sm flex items-center justify-center cursor-pointer">
            <Loading />
          </div>
        )}

        {!isLoading &&
          Array.isArray(props.value) &&
          props.value.map(
            (item, index) =>
              item && (
                <div style={{ width: "clamp(80px, 220px, 33%)" }}>
                  <div
                    key={index + item._id}
                    onDragStart={(e) => {
                      dragStart(e, index);
                    }}
                    onDragEnter={(e) => {
                      dragEnter(e, index);
                    }}
                    onDragEnd={(e) => {
                      dragDrop(e);
                    }}
                    onDragOver={(e) => {
                      dragOverFn(e);
                    }}
                    draggable={true}
                  >
                    <ContextMenu>
                      <ContextMenuTrigger>
                        <ImageComp {...item} w={"w-[100px]"} />
                      </ContextMenuTrigger>
                      <ContextMenuContent>
                        <ContextMenuItem
                          onClick={(e) => {
                            //prevent bubbling
                            e.stopPropagation();
                            const state = cloneDeep(props.value);
                            state.splice(index, 1);
                            props.onChange(state);
                          }}
                        >
                          Usuń
                        </ContextMenuItem>
                      </ContextMenuContent>
                    </ContextMenu>
                  </div>
                </div>
              )
          )}
        {(!fixDir || fixDir.length < 4) && (
          <div
            onClick={() => {
              dispatch(setInit(props.value));
              setOpenDialog(true);
            }}
            className="cursor-pointer"
          >
            <Card className="w-[180px] h-[180px] ml-[10px] rounded-sm flex items-center justify-center cursor-pointer">
              <UploadCloud color={"#454545"} size={"3em"} />
            </Card>
          </div>
        )}
      </div>
      {fixDir && (
        <div
          className="droparea w-full flex items-center justify-center min-h-[100px] border rounded-sm border-dashed border-black mt-10"
          onDrop={(e) => {
            e.preventDefault();
            handleUpload(e as any);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragStart={(e) => {
            e.preventDefault();
            setShowProgressBar(true);
          }}
        >
          <div className="droparea__text w-[80%] md:w-[70%] text-center py-[1em]">
            <div className="py-5">
              {" "}
              {isLoading ? (
                <Suspense fallback={<Loading />}>
                  <Loading />
                </Suspense>
              ) : (
                "Przeciągnij i upuść obrazek"
              )}
            </div>
            {showProgressBar && <Progress value={progress} />}
          </div>
        </div>
      )}

      {openDialog && (
        <DialogGallery
          open={openDialog}
          onOpenChange={() => {
            setOpenDialog(false);
          }}
          key={openDialog.toString()}
        >
          <DialogGalleryContent
            setDirmode={() => {
              dispatch(setDirMode(!dirmode));
            }}
            dirmode={dirmode}
            multiCheck={true}
            checkAll={imperHandles?.checkAll}
            unCheckAll={imperHandles?.unCheckAll}
          >
            <DialogGalleryHeader>
              <DialogGalleryTitle className="flex gap-5">
                {dirmode === true ? "Wybierz katalog" : "Wybierz obrazek"}
                {dirmode === false && currentDir !== "..." && (
                  <div className="text-sm uppercase">
                    <strong>{currentDir}</strong>
                  </div>
                )}
              </DialogGalleryTitle>
            </DialogGalleryHeader>
            <DialogGalleryDescription>
              <GalleryGrid
                ref={refgrid}
                selectedImage={props?.value}
                chooseMultiple={true}
              />
            </DialogGalleryDescription>
            <DialogGalleryFooter>
              <Button
                onClick={() => {
                  setOpenDialog(false);
                }}
              >
                Zamknij
              </Button>
              <Button
                onClick={() => {
                  setOpenDialog(false);

                  props.onChange(null);
                }}
              >
                Czyść obrazek
              </Button>
              <Button
                onClick={() => {
                  props.onChange(selectedImages);

                  setOpenDialog(false);
                }}
              >
                Zapisz
              </Button>
            </DialogGalleryFooter>
          </DialogGalleryContent>
        </DialogGallery>
      )}
    </div>
  );
});
GalleryPicker.displayName = "GalleryPicker";

export { GalleryPicker };
