import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceUrl } from "../config";
import { Cookies } from "react-cookie";

export const bookingApi = createApi({
  reducerPath: "bookingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceUrl,

    prepareHeaders: (headers) => {
      const token = new Cookies().get("token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set(
        "Access-Control-Allow-Methods",
        "GET, PUT, POST, DELETE, OPTIONS"
      );
      headers.set("WithCredentials", "true");

      headers.set("Access-Control-Allow-Credentials", "true");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Booking","UnseenBooking"],
  endpoints: (builder) => ({
    booking: builder.query({
      query: (arg) => {
        if (arg == undefined) {
          return { url: "/booking/list/", method: "GET" };
        } else {
          return { url: `/booking/${arg}/`, method: "GET" };
        }
      },
      providesTags: ["Booking"],
    }),
    bookingLimit: builder.query({
      query: () => {
        return { url: "/booking/limit/", method: "GET" };
      },

    }),
    addBooking: builder.mutation({
      query: (page) => ({
        url: "/booking/",
        method: "POST",
        body: page,
      }),
      invalidatesTags: ["Booking"],
    }),
    addFastBooking: builder.mutation({
      query: (page) => ({
        url: "/booking/fast/",
        method: "POST",
        body: page,
      }),
      invalidatesTags: ["Booking"],
    }),
    updateBooking: builder.mutation({
      query: ({ id, rest }) => ({
        url: `/booking/${id}/`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Booking"],
    }),
    deleteBooking: builder.mutation({
      query: (id) => ({
        url: `/booking/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Booking"],
    }),
    changeStatus: builder.mutation({
      query: ({ id, rest }) => ({
        url: `/booking/${id}/change_status/`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Booking"],
    }),
    addPayment: builder.mutation({
      query: ({ id, rest }) => ({
        url: `/booking/${id}/add_payment/`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Booking"],
    }),
    unseenBooking: builder.query({
        query: () => ({ url: "/booking/unseen/", method: "GET" }),
        providesTags: ["UnseenBooking"],

    }),
  }),
});

export const {
  useBookingQuery,
  useBookingLimitQuery,
  useAddBookingMutation,
  useAddFastBookingMutation,
  useUpdateBookingMutation,
  useDeleteBookingMutation,
  useChangeStatusMutation,
  useAddPaymentMutation,
  useUnseenBookingQuery,
} = bookingApi;
