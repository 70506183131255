import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const actionApi = createApi({
    reducerPath: 'actionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['action'],
    endpoints: (builder) => ({
        action: builder.query({
            query: (arg) => {


                    return { url: '/autoActions/', method: 'GET' }

            },

        }),
        addAction: builder.mutation({
            query: (page) => ({
                url: '/autoActions/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['action']
        }),
        updateAction: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/autoActions/`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['action']
        }),
        deleteAction: builder.mutation({
            query: (id) => ({
                url: `/autoActions/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['action']
        })
    })
});

export const { useActionQuery, useAddActionMutation, useUpdateActionMutation, useDeleteActionMutation } = actionApi;
