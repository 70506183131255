import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./select";

import { Label } from "./label";
import { UiInputProps } from "../types/types";
import { serviceUrl } from "../../config";
import { ScrollArea } from "./scroll-area";

const getData = async (endpoint) => {
  const response = await fetch(serviceUrl + "/" + endpoint);

  const data = await response.json();
  return data;
};

export function UiSelectEndpoint(props: UiInputProps & { action?: any }) {
  const [options, setOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    props?.endpoint &&
      getData(props.endpoint).then((data) => {
        console.log(data, "data");
        if (Object.keys(data).length === 1) {
          setOptions(data[Object.keys(data)[0]]);
        } else {
          setOptions(data);
        }
      });
  }, [props.endpoint]);

  const [current, setCurrent] = React.useState<any>(props.value || null);

  return (
    <div className="grid w-full">
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props.action}
      </div>

      {options.length > 0 && (
        <Select
          value={current}
          onValueChange={(value) => {
            setCurrent(value);
            props?.onChange?.(value);
          }}
        >
          <SelectTrigger className={`w-[180px] ${props?.sx}`}>
            <SelectValue placeholder="wybierz" />
          </SelectTrigger>
          <SelectContent>
            <ScrollArea className="h-[50vh]   p-4">
              <SelectGroup>
                {options.length > 0 &&
                  options.map((item: any, index: number) => {
                    return (
                      <SelectItem
                        key={index}
                        value={
                          props.valueendpoint &&
                          eval(`item${props.valueendpoint as string}`)
                        }
                      >
                        {item[props.labelendpoint as string]}
                      </SelectItem>
                    );
                  })}
              </SelectGroup>
            </ScrollArea>
          </SelectContent>
        </Select>
      )}
    </div>
  );
}
