import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const turnusItemApi = createApi({
    reducerPath: 'turnusItemApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['turnusItem'],
    endpoints: (builder) => ({
        turnusItem: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/turnusItem/', method: 'GET' }
                } else {
                    return { url: `/turnusItem/${arg}`, method: 'GET' }
                }
            },

        }),

    })
});

export const { useTurnusItemQuery } = turnusItemApi;
