import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardDescription,
  CardFooter,
} from "../../components/ui/card";
import { InputWithLabel } from "../../components/ui/inputWithLabel";
import { PasswordWithLabel } from "../../components/ui/passwordWithLabel";
import { Button } from "../../components/ui/button";

import { redirect, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../store/authSlice";

import { useLoginMutation } from "../../services/auth";
import type { LoginRequest } from "../../services/auth";

// import { version, name } from '../conf/version'

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempt, setAttempt] = React.useState(0);

  const [formState, setFormState] = React.useState<LoginRequest>({
    username: "",
    password: "",
  });

  const [error, setError] = React.useState<string | undefined>(undefined);

  const [login, result] = useLoginMutation();

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }));

  const logme = () => {
    setAttempt(attempt + 1);
    login(formState);
  };

  React.useEffect(() => {
    if (result?.data?.token) {
      dispatch(setCredentials(result.data));

      navigate("/pagetype/65c210f7d0dc8a84aa5c5aa1", { replace: true });
      navigate(0);
    } else {
      if (attempt !== 0) {
        setError("Błędne hasło lub login");
      }
    }
  }, [result]);

  const [focused, setFocused] = React.useState(false);
  return (
    <div
      className="flex items-center justify-center flex-col h-screen w-screen "
      style={{
        background: `linear-gradient(90deg, rgba(127,225,227,1) 0%, rgba(177,103,193,1) 52%, rgba(121,187,236,1) 100%)`,
      }}
    >
      {/* <img
        src="./logo.png"
        className={`w-[100px] mb-5
          transition-all duration-500 ease-in-out`}
      /> */}
      <div className="flex items-center justify-items-center justify-center">
        <Card className="backdrop-filter backdrop-blur-sm bg-opacity-10 bg-black text-[#fff]">
          <CardContent>
            <CardDescription>
              <InputWithLabel
                label="Login"
                onChange={handleChange}
                onFocus={() => {
                  setFocused(true);
                }}
                onBlur={
                  () => {
                    setFocused(false);
                  }
                  // setFocused(false)
                }
                whiteLabel
                name="username"
                className="bg-white text-black border-0 outline-0"
              />
              <PasswordWithLabel
                label="Hasło"
                onFocus={() => {
                  setFocused(true);
                }}
                onBlur={
                  () => {
                    setFocused(false);
                  }
                  // setFocused(false)
                }
                whiteLabel
                onChange={handleChange}
                name="password"
                className="text-black bg-white  border-0 outline-0"
              />
            </CardDescription>
          </CardContent>
          <CardFooter className="justify-end grid">
            <Button
              onClick={() => {
                logme();
              }}
            >
              Zaloguj
            </Button>
          </CardFooter>
          <div className="py-2 text-red-500 text-center">{error}</div>
        </Card>
      </div>
    </div>
  );
};

export default Login;
