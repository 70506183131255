export type FragmentTypes<T extends string> = {
  [key in T]: string;
};

const fragmentTypes: FragmentTypes<string> = {
  page: "Strona",
  system: "Systemowe",
  singleton: "Singleton",
  fragment: "Fragment",
  fragmentStatyczny: "Fragment Statyczny",
  form: "Formularz",
};

export { fragmentTypes };
