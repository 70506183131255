import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceUrl } from "../config";
import { Cookies } from "react-cookie";

export const massPromoApi = createApi({
  reducerPath: "massPromoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceUrl,

    prepareHeaders: (headers) => {
      const token = new Cookies().get("token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set(
        "Access-Control-Allow-Methods",
        "GET, PUT, POST, DELETE, OPTIONS"
      );
      headers.set("WithCredentials", "true");

      headers.set("Access-Control-Allow-Credentials", "true");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["MassPromo"],
  endpoints: (builder) => ({
    massPromo: builder.query({
      query: () => {

        return { url: "/massPromo/", method: "GET" };

      },
      providesTags: ["MassPromo"],
    }),

    addMassPromo: builder.mutation({
      query: (page) => ({
        url: "/massPromo/",
        method: "POST",
        body: page,
      }),
      invalidatesTags: ["MassPromo"],
    }),





  }),
});

export const {
  useMassPromoQuery,

  useAddMassPromoMutation,

} = massPromoApi;
