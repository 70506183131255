import {
  ChevronLeft,
  ChevronRight,
  Edit,
  Expand,
  MoreHorizontal,
  Shrink,
  Trash2,
} from "lucide-react";
import { ColumnOperationsProps } from "../../types/types";

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarTrigger,
} from "../menubar";

const ColumnOperations = (props: ColumnOperationsProps) => {
  return (
    <div>
      <Menubar className="border-0 ">
        <MenubarMenu>
          <MenubarTrigger className="cursor-pointer w-[30px] h-[30px]">
            <MoreHorizontal size={"15px"} />
          </MenubarTrigger>
          <MenubarContent>
            {props?.canLeft && props?.toLeft && (
              <MenubarItem
                disabled={!props.canLeft}
                onClick={() => {
                  if (props.canLeft && props?.toLeft) {
                    props.toLeft();
                  }
                }}
              >
                Przesuń w lewo
                <MenubarShortcut>
                  <ChevronLeft size={"15px"} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            {props?.canRight && (
              <MenubarItem
                disabled={!props.canRight}
                onClick={() => {
                  props.canRight && props?.toRight?.();
                }}
              >
                Przesuń w prawo
                <MenubarShortcut>
                  <ChevronRight size={"15px"} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            <MenubarSeparator />

            <MenubarSub>
              {props?.onShrink && (
                <MenubarItem onClick={props.onShrink}>
                  Zmniejsz pole
                  <MenubarShortcut>
                    <Shrink size={"15px"} />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              {props?.onExpand && (
                <MenubarItem onClick={props.onExpand}>
                  Powiększ pole
                  <MenubarShortcut>
                    <Expand size={"15px"} />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              <MenubarSeparator />

              <MenubarItem onClick={props.onAdd}>
                Edytuj pole{" "}
                <MenubarShortcut>
                  <Edit size={"15px"} />
                </MenubarShortcut>
              </MenubarItem>

              <MenubarItem onClick={props.onDelete}>
                Usuń kolumnę
                <MenubarShortcut>
                  <Trash2 size={"15px"} />
                </MenubarShortcut>
              </MenubarItem>
            </MenubarSub>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </div>
  );
};
export default ColumnOperations;
