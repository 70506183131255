import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const pageListApi = createApi({
    reducerPath: 'pageListApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['Page'],
    endpoints: (builder) => ({
        pageList: builder.query({
            query: (arg) => {
                return { url: '/page/list/', method: 'GET' }
            }

        }),
        pageListByType: builder.query({
            query: (arg) => {
                return { url: `/page/type/list/${arg}`, method: 'GET' }
            }

        }),



    })
});

export const { usePageListQuery, usePageListByTypeQuery } = pageListApi;
