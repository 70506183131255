import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const statusApi = createApi({
    reducerPath: 'statusApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['status'],
    endpoints: (builder) => ({
        status: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/status/', method: 'GET' }
                } else {
                    return { url: `/status/${arg}`, method: 'GET' }
                }
            },

        }),
        addStatus: builder.mutation({
            query: (page) => ({
                url: '/status/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['status']
        }),
        updateStatus: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/status/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['status']
        }),
        deleteStatus: builder.mutation({
            query: (id) => ({
                url: `/status/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['status']
        })
    })
});

export const { useStatusQuery, useAddStatusMutation, useUpdateStatusMutation, useDeleteStatusMutation } = statusApi;
