import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from "../../components/ui/menubar";

import { Trash, Menu, ArrowDown, ArrowUp } from "lucide-react";
type RowOperationsProps = {
  onDelete: (arg1: any, arg2: any) => void;
  onUp?: (arg1: any, arg2: any) => void;
  onDown?: (arg1: any, arg2: any) => void;
  showUp?: boolean;
  showDown?: boolean;
  elNum: any;
  index: any;
};

export const RowOperations = (props: RowOperationsProps) => {
  return (
    <div>
      <Menubar>
        <MenubarMenu>
          <MenubarTrigger>
            <Menu size={"1em"} />
          </MenubarTrigger>
          <MenubarContent>
            {props?.showUp && (
              <MenubarItem
                onClick={() => {
                  props?.onUp && props.onUp(props.index, props.elNum);
                }}
              >
                Do góry{" "}
                <MenubarShortcut>
                  <ArrowUp size={"1em"} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            {props?.showDown && (
              <MenubarItem
                onClick={() => {
                  props?.onDown && props.onDown(props.index, props.elNum);
                }}
              >
                W dół{" "}
                <MenubarShortcut>
                  <ArrowDown size={"1em"} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            {props?.showDown || (props?.showUp && <MenubarSeparator />)}
            <MenubarItem
              onClick={() => {
                props?.onDelete(props.index, props.elNum);
              }}
            >
              Usuń{" "}
              <MenubarShortcut>
                <Trash size={"1em"} />
              </MenubarShortcut>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </div>
  );
};
