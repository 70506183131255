import React from "react";
import "./App.css";
import Sidebar from "./components/layout/sidebar";
import Navbar from "./components/layout/navbar";

import TaneRouter from "./routing/taneRouter";

function App() {
  const route = window.location.pathname;

  return route === "/login" ? (
    <TaneRouter />
  ) : (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow">
        <Navbar />
        <div className="p-[1em] mainbox min-h-[80vh]">
          <div className="p-[2em]">
            <TaneRouter />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
