import React from "react";
import DataTable from "../../components/pageParts/table";
import ActionCell from "../../components/pageParts/ActionCell";
import {
  useDeletePageMutation,
  useAddPageMutation,
} from "../../services/pageCreateApi";
import { usePageListByTypeQuery } from "../../services/pageListApi";

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Plus } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Input } from "@/src/components/ui/input";
import { Label } from "@radix-ui/react-dropdown-menu";
import { InputWithLabel } from "../../components/ui/inputWithLabel";
const PageTypeList = () => {
  const navigate = useNavigate();
  const title = undefined;

  const { id } = useParams();

  const { refetch, data } = usePageListByTypeQuery(id);
  const [deletePage, response] = useDeletePageMutation();
  const [addPage, result] = useAddPageMutation();

  // const onSavePage = (values: any) => {
  //     const init = {
  //         schema_id:values.addPage[0].pageSchema?.$oid || values.addPage[0].pageSchema,
  //         "blocks": {
  //             "mainpage": [{
  //                     "slug": v.slugify(values.addPage[0].slug) as string,
  //                     "title": values.addPage[0].title
  //                 }]
  //         }
  //     }
  //     const x = savePage(init)

  // }

  // React.useEffect(() => {
  //     if (saveResponse.isSuccess) {
  //         refetch()
  //         navigate(`/page/${saveResponse.data._id.$oid}`);
  //     }
  // }, [saveResponse])

  React.useEffect(() => {
    if (response.isSuccess) {
      refetch();
    }
  }, [response]);

  // const headerCells = [
  //     {
  //         id: 'title',
  //         numeric: false,
  //         disablePadding: true,
  //         label: 'Tytuł',
  //         canOrder: true
  //     },
  //     {
  //         id: 'slug',
  //         numeric: false,
  //         disablePadding: false,
  //         label: 'Slug',
  //         canOrder: true
  //     },
  //     {
  //         id: 'pageType',
  //         numeric: false,
  //         disablePadding: false,
  //         label: 'Typ obiektu',
  //         canOrder: true
  //     }
  // ];

  React.useEffect(() => {
    if (response.isSuccess) {
      refetch();
    }
  }, [response]);

  const headerCells = [
    {
      accessorKey: "objectId",
      header: "objectId",
      visibilty: false,
      fancyName: "objectId",
    },
    {
      accessorKey: "title",
      header: "Tytuł",
      fancyName: "Tytył",
    },
    {
      accessorKey: "slug",
      header: "Slug",
      fancyName: "Slug",
    },
    {
      accessorKey: "pageType",
      header: "Typ",
      fancyName: "Typ",
    },

    {
      accessorKey: "action",
      allwaysVisible: true,
      fancyName: "objectId",
      header: () => {
        <div className="flex justify-end">Akcje</div>;
      },

      cell: ({ row }: any) => {
        return (
          <div className="justify-end flex text-[0.em]">
            <ActionCell deleteAction={deletePage} editLink={"page"} row={row} />
          </div>
        );
      },
    },
  ];

  const d = data?.map((el: any) => {
    return {
      objectId: el._id.$oid,
      title: el.title,
      slug: el.slug,
      pageType: el.pageType,
    };
  });

  const filters = [
    {
      field: "title",
      label: "Tytuł",
    },
    {
      field: "slug",
      label: "Slug",
    },
    {
      field: "pageType",
      label: "Typ",
    },
  ];

  const initialState: { pageTitle: string; slug: string } = {
    pageTitle: "",
    slug: "",
  };

  function reducer(
    state: typeof initialState,
    action: { type: string; payload: any }
  ) {
    switch (action.type) {
      case "SET_PAGE_TITLE":
        return { ...state, pageTitle: action.payload };
      case "SET_SLUG":
        return { ...state, slug: action.payload };
      default:
        return state;
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { pageTitle, slug } = state;

  const saveNewPage = () => {
    const init = {
      schema_id: id,
      blocks: {
        mainpage: [
          {
            slug: slug,
            title: pageTitle,
          },
        ],
      },
    };

    addPage(init);
  };

  React.useEffect(() => {
    if (result.isSuccess) {
      window.location.href = "/page/" + result.data?._id?.$oid;
    }
  }, [result]);

  const action = (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"ghost"} size={"sm"}>
          <Plus />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Dodaj stronę</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <InputWithLabel
            label="Slug"
            onChange={(e) => {
              dispatch({ type: "SET_SLUG", payload: e.target.value });
            }}
          />

          <InputWithLabel
            label="Tytuł"
            onChange={(e) => {
              dispatch({ type: "SET_PAGE_TITLE", payload: e.target.value });
            }}
          />
        </div>
        <DialogFooter>
          <Button
            variant={"outline"}
            onClick={() => {
              saveNewPage();
            }}
          >
            Zapisz
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  return (
    <div>
      {d && (
        <DataTable
          columns={headerCells}
          data={d}
          label="Lista stron"
          filters={filters}
          extraActions={[action]}
        />
      )}
    </div>
  );
};
export default PageTypeList;
