import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FolderCheck, FolderClosed, FolderMinus, Image, X } from "lucide-react";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
const DialogGallery = DialogPrimitive.Root;

const DialogGalleryTrigger = DialogPrimitive.Trigger;

const DialogGalleryPortal = DialogPrimitive.Portal;

const DialogGalleryOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
));
DialogGalleryOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogGalleryContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    dirmode: boolean;
    setDirmode: (boolean) => void;
    checkAll?: () => void;
    unCheckAll?: () => void;
    multiCheck?: boolean;
  }
>(({ className, children, ...props }, ref) => (
  <DialogGalleryPortal>
    <DialogGalleryOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "fixed left-[50%] top-[50%] z-50 grid w-auto max-w-[80%] translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
        className
      )}
      {...props}
    >
      {children}
      <div className="absolute right-4 top-4 opacity-70">
        <div className="flex items-center">
          <TooltipProvider>
            <Tooltip delayDuration={2}>
              <TooltipTrigger>
                <Button onClick={props.setDirmode} variant={"icon"}>
                  {props.dirmode !== true ? (
                    <FolderClosed
                      size={"0.8em"}
                      className="mr-5 cursor-pointer"
                    />
                  ) : (
                    <Image size={"0.8em"} className="mr-5 cursor-pointer" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {props.dirmode !== true ? "Lisa folderów" : "Lista plików"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          {props.multiCheck && props.dirmode !== true && (
            <>
              <TooltipProvider>
                <Tooltip delayDuration={2}>
                  <TooltipTrigger>
                    <Button
                      variant={"icon"}
                      onClick={() => {
                        props?.checkAll && props.checkAll();
                      }}
                    >
                      <FolderCheck
                        size={"0.8em"}
                        className="mr-5 cursor-pointer"
                      />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Zaznacz wszytskie z katalogu</TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip delayDuration={2}>
                  <TooltipTrigger>
                    <Button
                      variant={"icon"}
                      onClick={() => {
                        props?.unCheckAll && props.unCheckAll();
                      }}
                    >
                      <FolderMinus
                        size={"0.8em"}
                        className="mr-5 cursor-pointer"
                      />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Usuń zaznaczenie</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}

          <DialogPrimitive.Close className=" rounded-sm  ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        </div>
      </div>
    </DialogPrimitive.Content>
  </DialogGalleryPortal>
));
DialogGalleryContent.displayName = "DialogGalleryContent";

const DialogGalleryHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
);
DialogGalleryHeader.displayName = "DialogHeader";

const DialogGalleryFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 ",
      className
    )}
    {...props}
  />
);
DialogGalleryFooter.displayName = "DialogFooter";

const DialogGalleryTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
));
DialogGalleryTitle.displayName = DialogPrimitive.Title.displayName;

const DialogGalleryDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground w-full", className)}
    {...props}
  />
));
DialogGalleryDescription.displayName = DialogPrimitive.Description.displayName;

export {
  DialogGallery,
  DialogGalleryContent,
  DialogGalleryDescription,
  DialogGalleryFooter,
  DialogGalleryHeader,
  DialogGalleryOverlay,
  DialogGalleryPortal,
  DialogGalleryTitle,
  DialogGalleryTrigger,
};
