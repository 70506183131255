import * as React from "react";
import { Button } from "./button";
import { Label } from "./label";

import { UiInputProps } from "../types/types";
export function UiButton(
  props: UiInputProps & { action?: any; children?: any }
) {
  return (
    <div className="grid">
      <div className="flex gap-5 items-center">
        <Label>&nbsp; </Label>
        {props.action}
      </div>
      <Button
        {...props}
        onClick={(val) => {
          props?.onClick && props.onClick(val);
        }}
      >
        {props.label}{" "}
      </Button>
    </div>
  );
}
