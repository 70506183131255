// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    currentId: undefined,
    bookingNumber: undefined,
    tournusId: undefined,
} as { currentId: number | undefined, bookingNumber: string | undefined, tournusId: string | undefined };

// ==============================|| SLICE - MENU ||============================== //

const currentRowId = createSlice({
    name: 'currentRowId',
    initialState,
    reducers: {
        setCurrentRowId(state,action) {

            state.currentId = action.payload.id;
            state.bookingNumber = action.payload.bookingNumber;
            state.tournusId = action.payload.turnusId;
        },

    }
});
export default currentRowId
export const { setCurrentRowId } = currentRowId.actions;
