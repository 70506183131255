import React from "react";
import { UiInputProps } from "../types/types";

export const DynamicLabel = (props: UiInputProps & { action?: any }) => {
  return (
    <div className="grid">
      <div className="flex gap-5 items-center">
        <h3>{props?.label}</h3>
        {props?.action}
      </div>

      <div></div>
    </div>
  );
};
