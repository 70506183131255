import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const pageApi = createApi({
    reducerPath: 'pageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {
            const token = new Cookies().get('token');
           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['Page'],
    endpoints: (builder) => ({
        page: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/pageCreate/', method: 'GET' }
                } else {
                    return { url: `/pageCreate/${arg}`, method: 'GET' }
                }
            },

        }),
        addPage: builder.mutation({
            query: (page) => ({
                url: '/pageCreate/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['Page']
        }),
        updatePage: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/pageCreate/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['Page']
        }),
        deletePage: builder.mutation({
            query: (id) => ({
                url: `/pageCreate/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Page']
        })
    })
});

export const { usePageQuery, useAddPageMutation, useUpdatePageMutation, useDeletePageMutation } = pageApi;
