import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useRoutes } from "react-router-dom";
import Table2 from "../pages/table2";
import Table from "../pages/table";
import DocumentsList from "../pages/document/documentsList";
import Document from "../pages/document/document";
import TurnusList from "../pages/turnus/turnusList";
import TurnusCreate from "../pages/turnus/turnusCreate";
import Media from "../pages/media/media";
import PageCreateList from "../pages/pageCreator/pageCreateList";
import CreatePage from "../pages/pageCreator/createPage";
import PageGenerator from "../pages/pageGenerator/pageGenerator";
import PageGeneratorList from "../pages/pageGenerator/pageGeneratorList";
import CreatePageAdd from "../pages/pageCreator/createPageAdd";
import PageTypeList from "../pages/pageCreator/pageTypeList";
import Login from "../pages/login/login";
import MenuList from "../pages/menu/list";
import MenuAdd from "../pages/menu/add";
import ConfigCreate from "../pages/config/config";
import OrderList from "../pages/order/orderList";
import Order from "../pages/order/order";
import MassPromo from "../pages/massPromo/massPromo";
import KarnetList from "../pages/karnety/karnetyList";
import Karnet from "../pages/karnety/karnet";

const router = [
  {
    path: "/karnety",
    children: [
      {
        path: "list",
        element: <KarnetList />,
      },
      {
        path: "edit/:id",
        element: <Karnet />,
      },
    ],
  },
  {
    path: "/newconfig",
    element: <ConfigCreate />,
  },
  {
    path: "/masspromo",
    element: <MassPromo />,
  },
  {
    path: "/pagetype/:id",
    element: <PageTypeList />,
    display: false,
  },
  {
    path: "/",
    element: <Table />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/order",
    children: [
      {
        path: "list",
        element: <OrderList />,
      },
      {
        path: "edit/:id",
        element: <Order />,
      },
    ],
  },
  {
    path: "/menu",
    children: [
      {
        path: "list",
        element: <MenuList />,
      },
      {
        path: "add",
        element: <MenuAdd />,
      },
      {
        path: "edit/:id",
        element: <MenuAdd />,
      },
    ],
  },
  {
    path: "/creator",
    children: [
      {
        path: "add",
        element: <PageGenerator />,
      },
      {
        path: ":id",
        element: <PageGenerator />,
      },
      {
        path: "list",
        element: <PageGeneratorList />,
      },
    ],
  },
  {
    path: "/page",
    children: [
      {
        path: "list",
        element: <PageCreateList />,
      },
      {
        path: ":id",
        element: <CreatePage />,
      },
      {
        path: "add",
        element: <CreatePageAdd />,
      },
    ],
  },
  {
    path: "/2",
    element: <Table2 />,
  },
  {
    path: "/media",
    element: <Media />,
  },
  {
    path: "/turnus",
    children: [
      {
        path: "list",
        element: <TurnusList />,
      },
      {
        path: ":id",
        element: <TurnusCreate />,
      },
      {
        path: "add",
        element: <TurnusCreate />,
      },
      {
        path: "add",
        element: <TurnusCreate />,
      },
    ],
  },
  {
    path: "/document",

    children: [
      {
        path: ":id",
        element: <Document />,
      },
      {
        path: "list/",
        element: <DocumentsList />,
      },

      {
        path: "add",
        element: <Document />,
      },
    ],
  },
];

const TaneRouter = () => {
  return useRoutes(router);
};

export default TaneRouter;
