import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const pgApi = createApi({
    reducerPath: 'pgApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
         }}),
    tagTypes: ['Pg'],
    endpoints: (builder) => ({
        pg: builder.query({
            query: (arg) => {
                if(arg==undefined){
                    return { url: '/pageGen/', method: 'GET' }
                } else {

                    if(typeof(arg) == 'object'){
                        return { url: '/pageGen/by/', method: 'POST', body: { arg } }
                    }else{
                        return { url: `/pageGen/${arg}`, method: 'GET' }
                    }
                }
            },
            providesTags:['Pg']

        }),

        addPg: builder.mutation({
            query: (pg) => ({
                url: '/pageGen/',
                method: 'POST',
                body: pg
            }),
            invalidatesTags: ['Pg']
        }),
        updatePg: builder.mutation({
            query: ({ id, ...rest }) => ({
                url: `/pageGen/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['Pg']
        }),
        deletePg: builder.mutation({
            query: (id) => ({
                url: `/pageGen/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Pg']
        }),

    })
});

export const { usePgQuery,  useAddPgMutation, useUpdatePgMutation, useDeletePgMutation } = pgApi;
