import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { openredirect } from '../store/redirect';
import { Cookies } from 'react-cookie';
import { store } from '../store';



export const isAuth: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {


    if (new Cookies().get('token') === undefined && store.getState().redirect.isAuth === true) {
      api.dispatch(openredirect({ isAuth: false }))

    }

    return next(action)

  }