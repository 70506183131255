import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const turnusApi = createApi({
    reducerPath: 'turnusApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['turnus'],
    endpoints: (builder) => ({
        turnus: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/turnus/', method: 'GET' }
                } else {
                    return { url: `/turnus/${arg}`, method: 'GET' }
                }
            },

        }),

        addTurnus: builder.mutation({
            query: (turnus) => ({
                url: '/turnus/',
                method: 'POST',
                body: turnus
            }),
            invalidatesTags: ['turnus']
        }),
        updateTurnus: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/turnus/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['turnus']
        }),
        deleteTurnus: builder.mutation({
            query: (id) => ({
                url: `/turnus/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['turnus']
        })
    })
});

export const { useTurnusQuery, useAddTurnusMutation, useUpdateTurnusMutation, useDeleteTurnusMutation } = turnusApi;
