

type validatorType = {
    [key:string]:(value:any, regex?:any, test?:any) => any
}

const validators:validatorType = {

    number: (value: any, regex: any) => {

        return [isNaN(value), 'to pole musi być liczba'];
    },
    string: (value: any, regex: any) => {
        return [!isNaN(value), 'to pole nie może być liczbą'];
    },
    tekst: (value: any, regex: any) => {
        return [!isNaN(value), 'to pole nie może być liczbą'];
    },
    regex: (value: any, regex: string) => {
        const re = new RegExp(regex);
        return [re.test(value), 'błedne pole'];
    },
    email: (value, regex: string) => {
        //email adress regxep
        const re = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        return [!re.test(value), 'błedny email'];


    },
    date: (value: any, regex: any) => {



        const re = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
        const re2 = new RegExp(/^\d{2}\\d{2}\\d{4}$/);


            if (Object(value).hasOwnProperty('$d')) {
            return [(value.$d=='Invalid Date'), 'błedna data']
        }


        return [( !((new Date(value)).toString()!='Invalid Date')), 'błedna data'];
    },
    test: (value: any, test: (value: any) => boolean) => {
        return [test(value), 'błedne pole'];
    }
};


export const useWithValidation = (
    //callback: (name: string, value: any, blockName: string, num: number ) => void,
    errorCallback: (name: string, value: boolean, text: string, num: number ) => void,
    value: any,
    item: any, //TODO: TYPE
    name: string,
    num: number,
) => {


    if (item.validator != null && item.validator != 'none') {
        let [error, errorText] = validators[item!.validator](value, item?.regex);

        if ((item?.req != false || item?.req==undefined) && (value.length == 0 || value == null || value == undefined)) {
            error = false
        }


        if (item?.customError && error) {
            errorText = item.customError
        }

        errorCallback(item.name, error, error == true ? errorText : '',num);
    } else {
        errorCallback(item.name, false, '',num);
    }
    // callback(item.name, value, name, num);
}



export const useValidator = (
    errorCallback: (name: string, value: boolean, text: string) => void,
    value: any,
    item: any
) => {

    if (item.validator != null) {
        let [error, errorText] = validators[item.validator](value, item?.regex);
        if (item?.req == false && (value.length == 0 || value == null || value == undefined)) {
            error = false
        }

        if (item?.customError && error) {
            errorText = item.customError
        }
        errorCallback(item.name, error, error == true ? errorText : '');
    } else {
        errorCallback(item.name, false, '');
    }
};
