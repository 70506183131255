import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const karnetyApi = createApi({
    reducerPath: 'karnetyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['Karnety'],
    endpoints: (builder) => ({
        karnety: builder.query({
            query: (arg) => {

                if (arg == undefined) {
                    return { url: '/karnety/', method: 'GET' }
                } else {
                    return { url: `/karnety/${arg}/`, method: 'GET' }
                }
            },
            providesTags: ['Karnety']

        }),
        addKarnety: builder.mutation({
            query: (page) => ({
                url: '/karnety/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['Karnety']
        }),
        updateKarnety: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/karnety/${id}/`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['Karnety']
        }),
        deleteKarnety: builder.mutation({
            query: (id) => ({
                url: `/karnety/${id}/`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Karnety']
        }),
        changePaid: builder.mutation({
            query: ({ id, paid }) => ({
                url: `/karnety/changePaid/${id}/${paid}/`,
                method: 'PUT',
                body: { paid }
            }),
            invalidatesTags: ['Karnety']
        }),
    })
});

export const { useKarnetyQuery, useAddKarnetyMutation, useUpdateKarnetyMutation, useDeleteKarnetyMutation, useChangePaidMutation } = karnetyApi;
