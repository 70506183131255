import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import MenuItem from "./menuItem";
import TaneMenu from "./menuItemsList";
import { IMenuItem, IMenu } from "./types";
import { NavLink } from "react-router-dom";
import Icon from "./icon";
import { useExtramenuQuery } from "../../services/extramenuApi";

const Menu = () => {
  const { data: extramenu } = useExtramenuQuery("");

  return (
    <Accordion type="single" collapsible className="w-full border-0">
      {TaneMenu.map((menu: IMenu) => {
        return (
          <AccordionItem
            value={menu.name}
            key={menu.name}
            className="py-2 pr-5 "
          >
            <AccordionTrigger chevron={menu?.url ? false : true}>
              <div className="flex items-center opacity-100  text-black capitalize transition-all text-[0.9em]">
                {menu?.icon && (
                  <Icon name={menu.icon} color="black" size={"1em"} />
                )}
                {!menu?.url ? (
                  menu.name
                ) : (
                  <NavLink to={menu.url}>{menu.name}</NavLink>
                )}
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <ul className="ml-5 uppercase mt-3">
                {menu?.items?.map((subitem: IMenuItem) => {
                  return <MenuItem {...subitem} key={subitem.url} />;
                })}
              </ul>
            </AccordionContent>
          </AccordionItem>
        );
      })}
      <hr className="opacity-[40%] my-[1em]" />
      {extramenu &&
        extramenu.map((menu: any) => {
          const link = `/pagetype/${menu._id.$oid}`;
          return (
            <AccordionItem
              value={menu.menuName}
              key={menu.menuName}
              className="py-2 pr-5 "
            >
              <AccordionTrigger chevron={false}>
                <div className="flex items-center opacity-100   transition-all text-[0.9em] capitalize">
                  {menu.menuIcon.length > 0 && (
                    <Icon name={menu.menuIcon} color="black" size={"1.2em"} />
                  )}

                  <NavLink to={link}>{menu.menuName}</NavLink>
                </div>
              </AccordionTrigger>
            </AccordionItem>
          );
        })}
    </Accordion>
  );
};

export default Menu;
