import * as React from "react";

import { SelectMulti } from "./selectMulti";

import { Label } from "./label";
import { UiInputProps } from "../types/types";
import { serviceUrl } from "../../config";
import { on } from "events";

const getData = async (endpoint) => {
  const response = await fetch(serviceUrl + "/" + endpoint);
  const data = await response.json();
  return data;
};

export function UiSelectEndpointMulti(props: UiInputProps & { action?: any }) {
  const [options, setOptions] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<string[]>(props?.value || []);
  React.useEffect(() => {
    props?.endpoint &&
      getData(props.endpoint).then((data) => {
        const options = data.map((item: any, index: number) => {
          return {
            value: item[props.valueendpoint as string],
            label: item[props.labelendpoint as string],
          };
        });
        setOptions(options);
      });
  }, [props.endpoint]);

  React.useEffect(() => {
    props?.value && setSelected(props?.value);
  }, [props]);

  React.useEffect(() => {
    props?.onChange && props?.onChange(selected);
  }, [selected]);

  const toArray = (value: string | string[]) => {
    return Array.isArray(value) ? value : [value];
  };

  return (
    <div className="grid">
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props.action}
      </div>
      {options.length > 0 && (
        <SelectMulti
          options={options}
          selected={toArray(selected)}
          onChange={(value) => {
            setSelected(value);
          }}
          className="w-[560px]"
        />
      )}
    </div>
  );
}
