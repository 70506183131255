import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EnhancedFrom from "../../components/form/EnhancedFrom";
import { Card } from "../../components/ui/card";
import { usePgQuery } from "../../services/pageGeneratorApi";
import { Button } from "../../components/ui/button";
import {
  useMassPromoQuery,
  useAddMassPromoMutation,
} from "../../services/masspromoApi";

import { ImperativeHandle } from "../../components/form/EnhancedFrom";
type CreatePageProps = {};
const MassPromo = (props: CreatePageProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [schema, setSchema] = React.useState<any | undefined>(undefined);

  const [defaultValues, setDefault] = React.useState<any[]>([]);

  const [addMassPromo, result] = useAddMassPromoMutation();

  const { data: mainPageSchema } = usePgQuery({ slug: "mass_promo" });
  const { data: page_data } = useMassPromoQuery(undefined);

  React.useEffect(() => {
    if (params?.id == undefined) {
      setDefault([]);
    }
  }, [location]);

  React.useEffect(() => {
    if (mainPageSchema) {
      setSchema(mainPageSchema);
    }
  }, [mainPageSchema]);

  React.useEffect(() => {
    if (page_data) {
      setDefault([page_data.blocks]);
    } else {
      setDefault([]);
    }
  }, [page_data]);

  const save = (values: any) => {
    let page: any = {};
    page.schema_id = params.pageSchema;
    page.blocks = values;

    page.blocks = page.blocks;

    addMassPromo(page);
  };

  const formRef = React.useRef<ImperativeHandle>(null);

  return (
    <Card className="p-0">
      {schema && (
        <EnhancedFrom
          ref={formRef}
          values={defaultValues}
          schema={schema[0].pgSchema}
          onSave={(values: any) => {
            save(values);
          }}
          saveButton={false}
          inDialog={false}
        />
      )}

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ marginRight: "8px", marginTop: "2em" }}
          onClick={() => {
            formRef?.current?.saveEmit();
          }}
        >
          zapisz
        </Button>
      </div>
    </Card>
  );
};
export default MassPromo;
