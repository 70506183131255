import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const imprezaApi = createApi({
    reducerPath: 'imprezaApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['impreza'],
    endpoints: (builder) => ({
        impreza: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/impreza/', method: 'GET' }
                } else {
                    return { url: `/impreza/${arg}`, method: 'GET' }
                }
            },

        }),
        addImpreza: builder.mutation({
            query: (impreza) => ({
                url: '/impreza/',
                method: 'POST',
                body: impreza
            }),
            invalidatesTags: ['impreza']
        }),
        updateImpreza: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/impreza/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['impreza']
        }),
        deleteImpreza: builder.mutation({
            query: (id) => ({
                url: `/impreza/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['impreza']
        })
    })
});

export const { useImprezaQuery, useAddImprezaMutation, useUpdateImprezaMutation, useDeleteImprezaMutation } = imprezaApi;
