import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceUrl } from "../config";
import { Cookies } from "react-cookie";

export const collectionApi = createApi({
  reducerPath: "collectionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceUrl,

    prepareHeaders: (headers) => {
      const token = new Cookies().get("token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set(
        "Access-Control-Allow-Methods",
        "GET, PUT, POST, DELETE, OPTIONS"
      );
      headers.set("WithCredentials", "true");

      headers.set("Access-Control-Allow-Credentials", "true");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    collection: builder.query({
      query: (arg) => {


        return { url: `/collection/${arg}`, method: "GET" };

      },

    }),

  }),
});


export const {
  useCollectionQuery,

} = collectionApi;
