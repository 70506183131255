import React from 'react'

import { FieldProps } from '../../types/types'
import {Button} from '../../ui/button'

const Field = (props: FieldProps) => {

    //create dynamic icon
    const Icon = props.icon


    return (<div className="cursor-pointer grid justify-center items-center text-center" > <Button variant="ghost" onClick={() => { props.onClick() }}
        >
        <Icon size={'15px'} /></Button><div className="text-sm">{props.text}</div></div>)
}
export default Field