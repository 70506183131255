import { icons } from "../../lib/iconsList";

const Icon = ({ name, ...rest }: any) => {
  const Icon = icons[name];
  const style = rest.noBorder
    ? "mr-5 "
    : "w-[40px] h-[40px] border border-black flex items-center mr-[1em] rounded-md justify-center border-opacity-25";

  return (
    <div className={style}>
      <Icon {...rest} />
    </div>
  );
};

export default Icon;
