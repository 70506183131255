const Navbar = () => {
  return (
    <nav
      className="bg-white shadow-md p-4 sticky z-[90] w-[80%] hidden"
      style={{ top: 0 }}
    >
      <div className="">
        <h1 className="text-red text-2xl font-semibold"></h1>
      </div>
    </nav>
  );
};
export default Navbar;
