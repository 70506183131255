import * as React from "react";

import { SelectMulti } from "./selectMulti";

import { Label } from "./label";
import { UiInputProps } from "../types/types";
import { serviceUrl } from "../../config";
import { on } from "events";

export function UiSelectMulti(props: UiInputProps & { action?: any }) {
  const [selected, setSelected] = React.useState<string[]>(props?.value || []);

  // React.useEffect(() => {
  //   setSelected(props?.value || []);
  // }, [props?.value]);

  React.useEffect(() => {
    props?.onChange && props?.onChange(selected);
  }, [selected]);

  const toArray = (value: string | string[]) => {
    return Array.isArray(value) ? value : [value];
  };

  return (
    <div className="grid">
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props.action}
      </div>
      {props?.options.length > 0 && (
        <SelectMulti
          options={props?.options || []}
          selected={toArray(selected)}
          onChange={(value) => {
            setSelected(value);
          }}
          className="w-[560px]"
        />
      )}
    </div>
  );
}
