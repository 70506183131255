import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const bookingOthersApi = createApi({
    reducerPath: 'bookingOthersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,

        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
            headers.set('Access-Control-Allow-Origin', '*')
            headers.set("Access-Control-Allow-Methods", "GET, PUT, POST, DELETE, OPTIONS")
            headers.set("WithCredentials", "true");

            headers.set("Access-Control-Allow-Credentials", "true")

            if (token) {

             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['BookingOthers'],
    endpoints: (builder) => ({
        booking: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/bookingOthers/list/', method: 'GET' }
                } else {
                    return { url: `/bookingOthers/${arg}/`, method: 'GET' }
                }
            },

        }),
        bookingLimit: builder.query({
            query: () => {


                    return { url: '/bookingOthers/limit/', method: 'GET' }

            },

        }),
        addBookingOthers: builder.mutation({
            query: (page) => ({
                url: '/bookingOthers/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['BookingOthers']
        }),
        updateBookingOthers: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/bookingOthers/${id}/`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['BookingOthers']
        }),
        deleteBookingOthers: builder.mutation({
            query: (id) => ({
                url: `/bookingOthers/${id}/`,
                method: 'DELETE'
            }),
            invalidatesTags: ['BookingOthers']
        })
    })
});

export const { useBookingQuery, useBookingLimitQuery,  useAddBookingOthersMutation, useUpdateBookingOthersMutation, useDeleteBookingOthersMutation } = bookingOthersApi;
