"use client";

import * as React from "react";
import { Check, ChevronsUpDown, CopyPlus } from "lucide-react";

import { cn } from "../../lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { useCollectionQuery } from "../../services/collectionApi";

export function Combobox({
  endpoint,
  addOption,
}: {
  endpoint: any;
  addOption: (option: any) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [value, setValue] = React.useState("");

  const [options, setOptions] = React.useState([]);

  const { data } = useCollectionQuery(endpoint);

  React.useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  const current: any = options.find((option: any) => {
    return option?.blocks?.mainpage[0].slug === value;
  });

  return (
    <Popover open={open2} onOpenChange={setOpen2}>
      <PopoverTrigger asChild>
        <Button className="ml-2 " variant={"outline"} aria-expanded={open2}>
          <CopyPlus size={"1em"} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-5">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="w-[200px] justify-between"
            >
              {value ? current?.blocks?.mainpage[0].title : "wybierz opcję..."}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <Command>
              <CommandInput placeholder="Search framework..." />
              <CommandEmpty>Nie znaleziono opcji</CommandEmpty>
              <CommandGroup>
                {options.map((option: any, index) => {
                  console.log(option, " option");
                  return (
                    <CommandItem
                      key={option?.blocks?.mainpage[0].slug || index}
                      value={option?.blocks?.mainpage[0].slug}
                      onSelect={(currentValue) => {
                        console.log(
                          currentValue === option?.blocks?.mainpage[0].slug
                            ? "-"
                            : currentValue,
                          "currentValue"
                        );
                        setValue(
                          currentValue === option?.blocks?.mainpage[0].slug
                            ? option?.blocks?.mainpage[0].slug
                            : currentValue
                        );
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === option?.blocks?.mainpage[0].slug
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option?.blocks?.mainpage[0].title}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>

        <div className="px-5 pt-2 w-full flex justify-center">
          <Button
            onClick={() => {
              addOption(current);
              setOpen2(false);
              setOpen(false);
            }}
          >
            Dodaj
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
