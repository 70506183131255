import { cloneDeep } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import EnhancedFrom, {
  ImperativeHandle,
} from "../../components/form/EnhancedFrom";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardHeader } from "../../components/ui/card";
import {
  useAddPageMutation,
  usePageQuery,
  useUpdatePageMutation,
} from "../../services/pageCreateApi";
import { usePgQuery } from "../../services/pageGeneratorApi";
type CreatePageProps = {};

const CreatePage = (props: CreatePageProps) => {
  const params = useParams();

  const { data: mainPageSchema } = usePgQuery({ slug: "addPageForm" });

  const [addPage, result] = useAddPageMutation();

  const save = (values: any) => {
    let page: any = {};

    const { pageSchema, ...rest } = values.addPage[0];

    page.schema_id = pageSchema;
    page.blocks = {
      mainpage: [rest],
    };

    addPage(page);
  };

  React.useEffect(() => {
    if (result.isSuccess) {
      window.location.href = "/page/" + result.data?._id?.$oid;
    }
  }, [result]);

  const formRef = React.useRef<ImperativeHandle>(null);

  return (
    <Card>
      <CardContent>
        <CardHeader>Dodaj stronę</CardHeader>

        {mainPageSchema && (
          <EnhancedFrom
            ref={formRef}
            values={[]}
            schema={mainPageSchema[0].pgSchema}
            onSave={(values: any) => {
              save(values);
            }}
            saveButton={false}
            inDialog={false}
          />
        )}
      </CardContent>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ marginRight: "8px", marginTop: "2em" }}
          onClick={() => {
            formRef?.current?.saveEmit();
          }}
        >
          zapisz
        </Button>
      </div>
    </Card>
  );
};
export default CreatePage;
