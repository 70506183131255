import React from "react";
import EnhancedFrom, { ImperativeHandle } from '../../components/form/EnhancedFrom';
import { usePgQuery } from "../../services/pageGeneratorApi";

import {
    useAddDocumentMutation,
    useDocumentQuery,
    useUpdateDocumentMutation,
} from "../../services/documentApi";

import { useParams } from "react-router-dom";

import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";

type CreatePageProps = {};
const Document = (props: CreatePageProps) => {
  const params = useParams();

  const [schema, setSchema] = React.useState<any | undefined>(undefined);

  const [defaultValues, setDefault] = React.useState<any[]>([]);

  const { data: page_data } = useDocumentQuery(params.id, {
    skip: params.id == undefined,
  });

  const { data: schema_data } = usePgQuery({ slug: "document_system" });

  const [updateDocument] = useUpdateDocumentMutation();
  const [addDocument, result] = useAddDocumentMutation();

  React.useEffect(() => {
    setSchema(schema_data);
  }, [schema_data]);

  React.useEffect(() => {
    if (page_data) {
      setDefault([page_data[0].blocks]);
    }
  }, [page_data]);

  const save = (values: any) => {
    let page: any = {};
    page.schema_id = params.pageSchema;
    page.blocks = values;

    if (params.id !== undefined) {
      page.blocks = page.blocks;
      updateDocument({ id: params.id, rest: page });
    } else {
      addDocument(page);
    }
  };

  const formRef = React.useRef<ImperativeHandle>(null);

  return (
    <Card>
      {schema && (
            <EnhancedFrom
                ref={formRef}
                values={defaultValues}
                 schema={schema[0].pgSchema}
                onSave={(values: any) => {save(values)  }} saveButton={false} inDialog={false} />
        )}

      <div style={{ display: "flex", justifyContent: "end" }}>


        <Button style={{ marginRight: "8px", marginTop: "2em" }}      onClick={() => {formRef?.current?.saveEmit()  }}>zapisz</Button>
      </div>
    </Card>
  );
};
export default Document;
