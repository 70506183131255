import {
  useMediaQuery,
  useRotateMediaMutation,
  useUpdateMediaMutation,
} from "../../services/mediaApi";
import { useMediaDirsQuery } from "../../services/mediaDirsApi";
import React from "react";
import { Image } from "../ui/image";
import { ImagePlaceHolder } from "../ui/imagePlaceHolder";
import { DirImage } from "../ui/dirImage";
import { MoveLeft, MoveRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useImperativeHandle } from "react";
import { previewReducer, prviewInitalState } from "./reducers/preview";
import { metaTagsInitalState, metaTagsReducer } from "./reducers/metaTags";
import { ScrollArea } from "../ui/scroll-area";
import {
  addMultipleImages,
  addSingleImage,
  removeSingleImage,
  removeMultipleImages,
  addBachImages,
  setDirMode,
  setDirToDelete,
  setCurrentDir,
  removeAllImages,
} from "../../store/gallery";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../../components/ui/context-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
} from "../../components/ui/alert-dialog";
import { Music, Video } from "lucide-react";
import { Input } from "../ui/input";
import { cloneDeep } from "lodash";

type ImageType = {
  img: string;
  title?: string;
  alt?: string;
  checked?: boolean;
  dir?: string;
  file_name?: string;
  _id?: any;
};

interface GalleryGridProps {
  selectedImage?: any;
  chooseMultiple?: boolean;
  operationMode?: boolean;
}

const GalleryGrid = forwardRef(function (props: GalleryGridProps, ref) {
  const limit = 12;
  const dispatch = useDispatch();
  const selectedImages = useSelector((state: any) => state.galleryState.images);
  const dirmode = useSelector((state: any) => state.galleryState.dirMode);
  const currentDir = useSelector((state: any) => state.galleryState.currentDir);
  const [updateMedia] = useUpdateMediaMutation();
  const { data: images, refetch } = useMediaQuery(currentDir);
  const { data: dirList } = useMediaDirsQuery({});
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  //images pagination
  const indexOfLastImage = currentPage * limit;
  const indexOfFirstImage = indexOfLastImage - limit;
  const currentImages = images?.slice(indexOfFirstImage, indexOfLastImage);
  const maxPages = Math.ceil(images?.length / limit);

  const [previewState, previewDispatch] = React.useReducer(
    previewReducer,
    prviewInitalState
  );
  const [metaTagsState, metaTagsDispatch] = React.useReducer(
    metaTagsReducer,
    metaTagsInitalState
  );

  const [rotateMutation] = useRotateMediaMutation();

  const rotate = (item) => {
    rotateMutation({
      id: item,
    });
    refetch();
  };

  React.useEffect(() => {
    return () => {
      dispatch(removeAllImages());
    };
  }, []);

  const checkAllImages = React.useCallback(async () => {
    try {
      let images: ImageType[] = [];
      const res = await refetch();
      if (res?.data) {
        const _co = res.data.map((item: any) => ({
          ...item,
          _id: item._id.$oid,
        }));
        images = _co;
        dispatch(addBachImages(_co));
      }
      return images;
    } catch (error) {
      console.error("Error fetching images:", error);
      return [];
    }
  }, [dispatch, refetch]);

  const unCheckAllImages = React.useCallback(() => {
    dispatch(removeSingleImage());
  }, [dispatch]);

  useImperativeHandle(
    ref,
    () => {
      return {
        checkAllImages,
        unCheckAllImages,
        refetch,
      };
    },
    [checkAllImages, unCheckAllImages, refetch]
  );

  React.useEffect(() => {
    setCurrentPage(1);
  }, [currentDir]);

  const selectImages = (image: any) => {
    if (props.chooseMultiple === true) {
      if (!Array.isArray(selectedImages)) {
        dispatch(addMultipleImages(image));
      }
      if (
        Array.isArray(selectedImages) &&
        selectedImages?.findIndex((item: any) => item._id === image._id) !==
          -1 &&
        selectedImages !== undefined
      ) {
        dispatch(removeMultipleImages(image));
      } else {
        dispatch(addMultipleImages(image));
      }
    } else {
      if (image._id === selectedImages?._id) {
        dispatch(removeSingleImage());
      } else {
        dispatch(addSingleImage(image));
      }
    }
  };

  const saveMetaImages = () => {
    updateMedia({
      id: metaTagsState.currentItem?._id.$oid,
      rest: metaTagsState.currentItem,
    });
    metaTagsDispatch({ type: "CLOSE" });
  };

  return (
    <ScrollArea className="h-[70vh]   p-4">
      <AlertDialog open={metaTagsState.open}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <h1>Dodaj metatagi</h1>
            <AlertDialogDescription>
              <div className="grid gap-5 py-2">
                <Input
                  placeholder="Tytuł"
                  value={metaTagsState.currentItem?.title}
                  onChange={(event) => {
                    metaTagsDispatch({
                      type: "SET",
                      payload: { title: event.target.value },
                    });
                  }}
                />
                <Input
                  placeholder="Alt"
                  value={metaTagsState.currentItem?.alt}
                  onChange={(event) => {
                    metaTagsDispatch({
                      type: "SET",
                      payload: { alt: event.target.value },
                    });
                  }}
                />
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                metaTagsDispatch({ type: "CLOSE" });
              }}
            >
              Zamknij
            </AlertDialogAction>
            <AlertDialogAction
              onClick={() => {
                saveMetaImages();
              }}
            >
              Zapisz
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={previewState.open}>
        <AlertDialogContent className=" max-w-[60vw]">
          <AlertDialogHeader>
            <AlertDialogDescription>
              <>
                <img
                  src={`https://backkijek.koniar3.usermd.net/media/serve/${previewState?.image?._id.$oid}?w=1920&ext=webp`}
                  className="max-h-[80vh] w-full object-contain"
                  alt={previewState?.image?.alt || ""}
                />
              </>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                previewDispatch({ type: "CLOSE" });
              }}
            >
              zamknij
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={previewState.open}>
        <AlertDialogContent className=" max-w-[60vw]">
          <AlertDialogHeader>
            <AlertDialogDescription>
              <>
                <img
                  src={`https://backkijek.koniar3.usermd.net/media/serve/${previewState?.image?._id.$oid}?w=1920&ext=webp`}
                  className="max-h-[80vh] w-full object-contain"
                  alt={previewState?.image?.alt || ""}
                />
              </>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                previewDispatch({ type: "CLOSE" });
              }}
            >
              zamknij
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {dirmode === true && (
        <div>
          <div className="flex flex-wrap gap">
            {["...", ...dirList]?.map((item: any) => {
              return (
                <div
                  className="my-5"
                  onClick={(e) => {
                    if (e.nativeEvent.button !== 2) {
                      dispatch(setCurrentDir(item));
                      dispatch(setDirMode(!dirmode));
                    }
                  }}
                  key={currentDir}
                  style={{ width: "clamp(80px, 220px, 33%)" }}
                >
                  <ContextMenu>
                    <ContextMenuTrigger>
                      <DirImage currentDir={currentDir} name={item} />
                    </ContextMenuTrigger>
                    <ContextMenuContent>
                      <ContextMenuItem
                        onClick={(e) => {
                          //prevent bubbling
                          e.stopPropagation();
                          dispatch(setDirToDelete(item));
                        }}
                      >
                        Usuń
                      </ContextMenuItem>
                    </ContextMenuContent>
                  </ContextMenu>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {dirmode === false && (
        <div>
          {currentImages?.length === 0 && (
            <div className="text-center py-10">Brak mediów w tym katalogu</div>
          )}
          <div className="flex flex-wrap gap-[2em] justify-start">
            {currentImages?.map((item: any) => {
              return (
                <div
                  onClick={() => {
                    selectImages({ ...item, _id: item._id.$oid });
                  }}
                  key={item._id.$oid + "" + item.orientation || "0"}
                  className="bg-[#f5f5f5] rounded-sm"
                  style={{ width: "clamp(80px, 250px, 33%)" }}
                >
                  <ContextMenu>
                    <ContextMenuTrigger>
                      {["jpg", "webp", "gif", "png", "jpeg"].includes(
                        item.ext.toLowerCase()
                      ) && (
                        <Image
                          {...item}
                          oid
                          selected={selectedImages}
                          showSelected={true}
                          orientation={item.orientation}
                        />
                      )}
                      {["mp3", "mp4", "ogg", "avi"].includes(
                        item.ext.toLowerCase()
                      ) && (
                        <ImagePlaceHolder
                          {...item}
                          oid
                          selected={selectedImages}
                          showSelected={true}
                          filename={item.file_name}
                        >
                          {["mp3", "ogg"].includes(item.ext.toLowerCase()) && (
                            <Music size={"2em"} />
                          )}
                          {["mp4", "avi"].includes(item.ext.toLowerCase()) && (
                            <Video size={"2em"} />
                          )}
                        </ImagePlaceHolder>
                      )}
                    </ContextMenuTrigger>
                    <ContextMenuContent>
                      <ContextMenuItem
                        onClick={(e) => {
                          //prevent bubbling
                          e.stopPropagation();
                          previewDispatch({ type: "OPEN", payload: item });
                        }}
                      >
                        Zobacz
                      </ContextMenuItem>

                      <ContextMenuItem
                        onClick={(e) => {
                          //prevent bubbling
                          e.stopPropagation();
                          rotate(item._id.$oid);
                        }}
                      >
                        Obróć
                      </ContextMenuItem>

                      <ContextMenuItem
                        onClick={(e) => {
                          //prevent bubbling
                          e.stopPropagation();

                          metaTagsDispatch({ type: "OPEN", payload: item });
                        }}
                      >
                        Ustaw metatagi
                      </ContextMenuItem>
                    </ContextMenuContent>
                  </ContextMenu>
                </div>
              );
            })}
          </div>
          {maxPages > 0 && (
            <div className="flex justify-center items-center mt-4">
              {currentPage > 1 && (
                <div
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  className="cursor-pointer"
                >
                  <MoveLeft size={"2em"} />
                </div>
              )}
              <div className="mx-4">
                {currentPage} / {maxPages}
              </div>
              {currentPage < maxPages && (
                <div
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  className="cursor-pointer"
                >
                  <MoveRight size={"2em"} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </ScrollArea>
  );
});

export default GalleryGrid;
