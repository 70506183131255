// types
import { createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';


// initial state
const initialState = {
    isAuth: new Cookies().get('token') === undefined ? false : true,
    ignoredPath: '/login',

};

// ==============================|| SLICE - MENU ||============================== //


const redirect = createSlice({
    name: 'redirect',
    initialState,

    reducers: {
        openredirect(state, action) {

            state.isAuth = action.payload.isAuth;
            if(action.payload.isAuth===false)
            {
                window.location.href = '/login'
            }

        },






    }
});
export default redirect
export const { openredirect } = redirect.actions;
