import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";

import { cn } from "../../lib/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn("", className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger> ,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>  & {chevron?: boolean }
  >(({ className, children, ...props }, ref) => {
    //remove cheveron from props
    const {chevron, ...rest} = props;
  return  (



    <AccordionPrimitive.Header className='flex'>
      {chevron == false && <span className="text-sm">{children}</span>}
      { chevron!=false && (
        <AccordionPrimitive.Trigger
          ref={ref}
          className={cn(
            "flex flex-1 text-sm items-center font-normal justify-between  transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
            className
          )}
          {...rest}
        >
          {children}

         <ChevronDown className='h-4 w-4 shrink-0 transition-transform duration-200' />
        </AccordionPrimitive.Trigger>
        )}
      </AccordionPrimitive.Header>
    )
  }
  );
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className
    )}
    {...props}
  >
    <div className='pb-4 pt-0'>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
