import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./select";

import { Label } from "./label";
import { UiInputProps } from "../types/types";
import { ScrollArea } from "./scroll-area";
export function UiSelect(props: UiInputProps & { action?: any }) {
  return (
    <div className="grid w-full">
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props.action}
      </div>
      <Select
        value={props?.value}
        onValueChange={(value) => {
          props?.onChange?.(value);
        }}
      >
        <SelectTrigger className="min-w-[180px]">
          <SelectValue placeholder="wybierz" />
        </SelectTrigger>
        <SelectContent>
          <ScrollArea className="h-[80vh]   p-4">
            <SelectGroup>
              {props?.options?.map((item: any, index: number) => {
                {
                  return (
                    item && (
                      <SelectItem key={index.toString()} value={item.value}>
                        {item.label}
                      </SelectItem>
                    )
                  );
                }
              })}
            </SelectGroup>
          </ScrollArea>
        </SelectContent>
      </Select>
    </div>
  );
}
