import * as React from "react";

import { SelectMulti } from "./selectMulti";
import { CheckboxWithLabelInline } from "./checkboxWithLabelInline";
import { Label } from "./label";
import { UiInputProps } from "../types/types";
import { serviceUrl } from "../../config";
import _, { cloneDeep, set } from "lodash";
import { ChevronsUpDown, ChevronsDownUp } from "lucide-react";
import { ClickScrollPlugin } from "overlayscrollbars";
import { NodeBuilderFlags } from "typescript";
const getData = async (endpoint) => {
  const response = await fetch(serviceUrl + "/" + endpoint);
  const data = await response.json();
  return data;
};

export function UiCheckBoxTree(props: UiInputProps & { action?: any }) {
  const [options, setOptions] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>(props?.value || {});
  const [key, setKey] = React.useState<any>(0);

  const [currentOpen, setCurrentOpen] = React.useState<any>(null);

  React.useEffect(() => {
    props?.endpoint &&
      getData(props.endpoint).then((data) => {
        const opt: any = [];
        data.map((item: any, index: number) => {
          const w = {
            subcategory: cloneDeep(item.subcategory) || "",
            subslug: cloneDeep(item?.subslug) || "",
          };
          delete item.subslug;
          delete item.subcategory;

          item?.subBlocks?.subkategories?.unshift(w);
          opt.push(item);
        });

        setOptions(opt);
      });
  }, [props.endpoint]);

  React.useEffect(() => {
    props?.value && setSelected(props?.value);
  }, []);

  React.useEffect(() => {
    props?.onChange && props?.onChange(selected);
    setKey(key + 1);
  }, [selected]);

  const setCategory = (e, slug) => {
    const _selected = cloneDeep(selected);
    _selected[slug] = { value: e };
    if (e == false) {
      // _selected[slug]["subcategories"] = {};
    } else {
      options?.map((item) => {
        if (item?.slug == slug) {
          if (_selected?.[slug]?.["subcategories"] == undefined) {
            _selected[slug]["subcategories"] = {};
          }
          // item?.subBlocks?.subkategories?.map((subitem) => {
          //   _selected[slug]["subcategories"][subitem.subslug] = true;
          // });
        }
      });
    }

    setSelected(_selected);
  };

  const setSubCategory = (e, slug, categorySlug) => {
    const _selected = cloneDeep(selected);

    if (e == true) {
      _selected[categorySlug]["value"] = e;
    }

    if (_selected?.[categorySlug]?.["subcategories"] == undefined) {
      _selected[categorySlug]["subcategories"] = {};
    }

    _selected[categorySlug]["subcategories"][slug] = e;
    // const parentToCheck = parentCheck(categorySlug, _selected);
    // if (!parentToCheck) {
    //   _selected[categorySlug]["value"] = false;
    // }

    setSelected(_selected);
  };

  const parentCheck = (categorySlug, _selected) => {
    const anyTrue = Object.values(
      _selected[categorySlug]["subcategories"]
    ).some((value) => value);
    return anyTrue;
  };

  return (
    <div className="grid">
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props.action}
      </div>
      <div className="grid grid-cols-3" key={key}>
        {options?.map((item, index) => {
          return (
            <div key={index + key}>
              <div className="flex gap-5 items-center">
                <CheckboxWithLabelInline
                  label={item.name}
                  checked={selected?.[item?.slug]?.value || false}
                  onCheckedChange={(e) => {
                    setCategory(e, item.slug);
                  }}
                />
                {currentOpen !== item?.slug ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setCurrentOpen(item?.slug);
                    }}
                  >
                    <ChevronsUpDown size={13} />
                  </div>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setCurrentOpen(null);
                    }}
                  >
                    <ChevronsDownUp size={13} />
                  </div>
                )}
              </div>

              <div
                className={`ml-5 ${
                  currentOpen == item?.slug
                    ? "transition-all h-full max-h-[150em] duration-[400ms] opacity-100 "
                    : "max-h-[0px] h-[0px] overflow-clip transition-all duration-[400ms] opacity-0"
                }`}
              >
                {item.subBlocks?.subkategories?.map((subitem, subindex) => {
                  return (
                    <div>
                      <CheckboxWithLabelInline
                        label={subitem.subcategory}
                        checked={
                          selected?.[item?.slug]?.["subcategories"]?.[
                            subitem?.subslug
                          ] || false
                        }
                        onCheckedChange={(e) => {
                          setSubCategory(e, subitem.subslug, item.slug);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
