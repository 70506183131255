import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceUrl } from "../config";
import { Cookies } from "react-cookie";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceUrl,

    prepareHeaders: (headers) => {
      const token = new Cookies().get("token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set(
        "Access-Control-Allow-Methods",
        "GET, PUT, POST, DELETE, OPTIONS"
      );
      headers.set("WithCredentials", "true");

      headers.set("Access-Control-Allow-Credentials", "true");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    order: builder.query({
      query: (arg) => {
        if (arg == undefined) {
          return { url: "privateorder/", method: "GET" };
        } else {
          return { url: `privateorder/${arg}/`, method: "GET" };
        }
      },
      providesTags: ["Order"],
    }),


    updateOrder: builder.mutation({
      query: ({ id, rest }) => ({
        url: `privateorder/${id}/`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Order"],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `privateorder/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Order"],
    }),

    sendStatusMail: builder.mutation({
      query: (id) => ({
        url: `privateorder/sendStatusMail/${id}/`,
        method: "GET",
      }),
      invalidatesTags: ["Order"],
    }),

    changeProdAvail: builder.mutation({
      query: ({ id, value }) => ({
        url: `changeProductAvailability/${id}`,
        method: "POST",
        body: { value: value },
      }),

    }),


  }),
});

export const {
  useOrderQuery,

  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useSendStatusMailMutation,
  useChangeProdAvailMutation
} = orderApi;
