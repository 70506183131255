export const prviewInitalState:{open:boolean,image:any} = {
    open: false,
    image: null,
  };

  export type PreviewActions = {type:'OPEN',payload:any}|{type:'CLOSE'}

 export const previewReducer = (state: typeof prviewInitalState, action: PreviewActions) => {
    switch (action.type) {
      case 'OPEN':
        return {
          ...state,
          open: true,
          image: action.payload
        }
      case 'CLOSE':
        return {
          ...state,
          open: false,
          image: null
        }
      default:
        return state
    }
  }
