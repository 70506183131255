import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'
import { serviceUrl } from '../config'

export interface UserResponse {
  token: string,
  username: string,
  image: any,
  role: string
}

export interface LoginRequest {
  username: string
  password: string
}

export const userApi = createApi({
  reducerPath: 'Api',
  baseQuery: fetchBaseQuery({
    baseUrl: serviceUrl,

  }),

  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
    // protected: builder.mutation<{ message: string }, void>({
    //   query: () => 'protected',
    // }),
  }),
})

export const { useLoginMutation } = userApi
