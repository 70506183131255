import { cloneDeep } from "lodash";
import React, { Reducer } from "react";

import AddFieldDialog from "./formCreator/AddFieldDialog";
import { FormCreatorProps } from "../types/types";
import { Checkbox } from "./checkbox";
import ColumnOperations from "./formCreator/ColumnOperations";
import RowOperations from "./formCreator/RowOperations";

import {
  ChevronDown,
  ChevronUp,
  Import,
  Square,
  SquareStack,
  Trash2,
  Plus,
  FoldVertical,
} from "lucide-react";

import { Components } from "../../components/form/Components";
import { Button } from "../../components/ui/button";
import { InputWithLabel } from "../../components/ui/inputWithLabel";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

import { usePgQuery } from "../../services/pageGeneratorApi";
import { fieldStateType } from "../types/types";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../components/ui/dialog";
import { sub } from "date-fns";

const FormCreator = (props: FormCreatorProps) => {
  type itemType = {
    input: any;
    name: string;
    label: string;
    sx: any;
    defaultValue?: any;
    validator?: string;
    regex?: string;
    value?: any;
    required?: boolean;
    options?: any;
    endpoint?: string;
    valueEndpoint?: string;
    labelEndpoint?: string;
    readonly?: boolean;
    autoOperation?: string;
    systemfunc?: string;
    fixedDirectory?: string;
    subItems?: itemType[];
  };

  type rowType = {
    multi?: boolean;
    subBlockName?: string;
    block: {
      sx: any;

      items: itemType[];
    };
  };

  const newItem = {
    input: "unk",
    name: "-",
    label: "Nowe pole",
    sx: { width: "100%" },
  };
  type mainBlockType = {
    mainBlock: {
      multiple: boolean;
      name: string;
      displayName?: string;
      flodAble?: boolean;
      blocks: rowType[];
      copyFrom?: string;
    };
  };

  const addColumn = (mainblockIndex: number, i: number) => {
    const newSchema = cloneDeep(schema);
    let cols =
      newSchema[mainblockIndex].mainBlock!.blocks[i].block.sx
        .gridTemplateColumns;

    newSchema[mainblockIndex].mainBlock!.blocks[
      i
    ].block.sx.gridTemplateColumns = cols + " 1fr";

    newSchema[mainblockIndex].mainBlock!.blocks[i].block.items.push(newItem);

    setSchema([...newSchema]);
  };

  const newRow: rowType = {
    block: {
      sx: { display: "grid", gridTemplateColumns: "1fr", gap: "10px" },
      items: [newItem],
    },
  };

  const newMainBlock: mainBlockType = {
    mainBlock: {
      multiple: false,
      name: "",
      displayName: "",
      blocks: [newRow],
    },
  };
  const [inital, setIntial] = React.useState<boolean>(false);
  const [schema, setSchema] = React.useState<mainBlockType[]>([newMainBlock]);

  React.useEffect(() => {
    setSchema(props?.schema);
  }, [props?.schema]);

  const operateColumnTemplate = (
    mainblockIndex: number,
    row: number,
    item: number,
    add: boolean
  ) => {
    const newSchema = cloneDeep(schema);
    let colTemplate =
      newSchema[mainblockIndex].mainBlock.blocks[
        row
      ].block.sx.gridTemplateColumns.split(" ");
    let fr = Number(colTemplate[item].replace("fr", ""));
    fr = add == true ? fr + 1 : fr - 1 == 0 ? (fr = 1) : fr - 1;
    colTemplate[item] = fr + "fr";
    newSchema[mainblockIndex].mainBlock.blocks[
      row
    ].block.sx.gridTemplateColumns = colTemplate.join(" ");
    setSchema([...newSchema]);
  };

  const upRow = (mainblockIndex: number, index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[mainblockIndex].mainBlock.blocks[index] = newSchema[
      mainblockIndex
    ].mainBlock.blocks.splice(
      index - 1,
      index,
      newSchema[mainblockIndex].mainBlock.blocks[index]
    )[0];
    setSchema([...newSchema]);
  };
  const downRow = (mainblockIndex: number, index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[mainblockIndex].mainBlock.blocks[index + 1] = newSchema[
      mainblockIndex
    ].mainBlock.blocks.splice(
      index,
      index + 1,
      newSchema[mainblockIndex].mainBlock.blocks[index + 1]
    )[0];
    setSchema([...newSchema]);
  };

  const toLeft = (mainblockIndex: number, row: number, item: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[mainblockIndex].mainBlock.blocks[row].block.items[item - 1] =
      newSchema[mainblockIndex].mainBlock.blocks[row].block.items.splice(
        item,
        1,
        newSchema[mainblockIndex].mainBlock.blocks[row].block.items[item - 1]
      )[0];
    const columns =
      newSchema[mainblockIndex].mainBlock.blocks[
        row
      ].block.sx.gridTemplateColumns.split(" ");
    columns[item - 1] = columns.splice(item, 1, columns[item - 1])[0];

    newSchema[mainblockIndex].mainBlock.blocks[
      row
    ].block.sx.gridTemplateColumns = columns.join(" ");
    setSchema([...newSchema]);
  };
  const toRight = (mainblockIndex: number, row: number, item: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[mainblockIndex].mainBlock.blocks[row].block.items[item + 1] =
      newSchema[mainblockIndex].mainBlock.blocks[row].block.items.splice(
        item,
        1,
        newSchema[mainblockIndex].mainBlock.blocks[row].block.items[item + 1]
      )[0];
    const columns =
      newSchema[mainblockIndex].mainBlock.blocks[
        row
      ].block.sx.gridTemplateColumns.split(" ");
    columns[item + 1] = columns.splice(item, 1, columns[item + 1])[0];

    newSchema[mainblockIndex].mainBlock.blocks[
      row
    ].block.sx.gridTemplateColumns = columns.join(" ");
    setSchema([...newSchema]);
  };

  const onAdd = (mainblockIndex: number, row: number, item: number) => {
    dispatchDialog({
      type: "open",
      payout: {
        mainblockIndex: mainblockIndex,
        subItem: false,
        row: row,
        item: item,
        field: schema[mainblockIndex].mainBlock.blocks[row].block.items[item],
      },
    });
  };

  const onEditSubItem = (
    mainblockIndex: number,
    row: number,
    item: number,
    indexEl: number
  ) => {
    dispatchDialog({
      type: "open",
      payout: {
        mainblockIndex: mainblockIndex,
        subItem: true,
        subItemPosition: indexEl,
        row: row,
        item: item,
        field:
          //@ts-ignore
          schema[mainblockIndex].mainBlock.blocks[row].block.items[item]
            .subItems[indexEl],
      },
    });
  };

  const deleteSubItem = (
    mainblockIndex: number,
    row: number,
    item: number,
    indexEl: number
  ) => {
    const newSchema = cloneDeep(schema);
    if (
      newSchema[mainblockIndex]?.mainBlock?.blocks[row]?.block?.items[item]
        ?.subItems
    ) {
      //@ts-ignore
      newSchema[mainblockIndex].mainBlock.blocks[row].block.items[
        item
      ].subItems.splice(indexEl, 1);
    }

    setSchema([...newSchema]);
  };

  const deleteItem = (mainblockIndex: number, row: number, item: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[mainblockIndex].mainBlock.blocks[row].block.items.splice(item, 1);
    const columns =
      newSchema[mainblockIndex].mainBlock.blocks[
        row
      ].block.sx.gridTemplateColumns.split(" ");
    columns.splice(item, 1);
    newSchema[mainblockIndex].mainBlock.blocks[
      row
    ].block.sx.gridTemplateColumns = columns.join(" ");
    setSchema([...newSchema]);
  };

  const deleteRow = (mainblockIndex: number, row: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[mainblockIndex].mainBlock.blocks.splice(row, 1);
    setSchema([...newSchema]);
  };

  type dialogState = {
    subItem?: boolean;
    dialogOpen: boolean;
    row?: number;
    item?: number;
    field?: any;
    mainblockIndex?: number;
    subItemPosition?: number;
  };

  const initalDialogState: dialogState = {
    subItem: false,

    dialogOpen: false,
    mainblockIndex: undefined,
    row: undefined,
    item: undefined,
    field: undefined,
  };

  type actionDialog = {
    type: "open" | "close";
    payout?: {
      row: number;
      item: number;
      field?: any;
      mainblockIndex: number;
      subItem?: boolean;
      subItemPosition?: number;
    };
  };

  const addingDialogReducer = (
    state: dialogState,
    action: actionDialog
  ): dialogState => {
    switch (action.type) {
      case "open":
        return {
          dialogOpen: true,
          mainblockIndex: action?.payout?.mainblockIndex,
          row: action?.payout?.row,
          item: action?.payout?.item,
          field: action.payout?.field,
          subItem: action.payout?.subItem ? true : false,
          subItemPosition: action.payout?.subItemPosition,
        };
      case "close":
        return {
          dialogOpen: false,
          row: undefined,
          item: undefined,
          subItem: false,
          subItemPosition: undefined,
        };
      default:
        return state;
    }
  };

  const [addingDialogState, dispatchDialog] = React.useReducer<
    Reducer<dialogState, actionDialog>
  >(addingDialogReducer, initalDialogState);

  const save = (el: fieldStateType) => {
    const newSchema = cloneDeep(schema);

    const [mainblockIndex, row, item, subItem, subItemPosition] = [
      addingDialogState.mainblockIndex,
      addingDialogState.row,
      addingDialogState.item,
      addingDialogState.subItem,
      addingDialogState.subItemPosition,
    ];

    console.log(subItem, "subItem", subItemPosition, "subItemPosition");

    console.log(subItem, "subItem");
    if (mainblockIndex != undefined && row != undefined && item != undefined) {
      if (!subItem) {
        const current =
          newSchema[mainblockIndex].mainBlock.blocks[row].block.items[item];

        current.label = el.label;
        current.name = el.name;
        current.sx = el?.style;

        current.validator = el.validatationType;
        current.regex = el.regexp;
        current.input = el.component;
        current.value = el.defaultValue;
        current.required = el.required;
        current.readonly = el.readonly;
        current.options = el.options;
        current.endpoint = el.endpoint;
        current.labelEndpoint = el.labelEndpoint;
        current.valueEndpoint = el.valueEndpoint;
        current.autoOperation = el.autoOperation;
        current.systemfunc = el.systemfunc;
        current.fixedDirectory = el.fixedDirectory;
      } else {
        const current: itemType = newItem;
        current.label = el.label;
        current.name = el.name;
        current.sx = el?.style;

        current.validator = el.validatationType;
        current.regex = el.regexp;
        current.input = el.component;
        current.value = el.defaultValue;
        current.required = el.required;
        current.readonly = el.readonly;
        current.options = el.options;
        current.endpoint = el.endpoint;
        current.labelEndpoint = el.labelEndpoint;
        current.valueEndpoint = el.valueEndpoint;
        current.autoOperation = el.autoOperation;
        current.systemfunc = el.systemfunc;
        current.fixedDirectory = el.fixedDirectory;
        console.log(subItemPosition, "subItemPosition");
        switch (subItemPosition) {
          case undefined:
            if (
              !newSchema[mainblockIndex]?.mainBlock?.blocks[row]?.block?.items[
                item
              ]?.subItems
            ) {
              newSchema[mainblockIndex].mainBlock.blocks[row].block.items[
                item
              ].subItems = [current];
            } else {
              //@ts-ignore
              newSchema[mainblockIndex].mainBlock.blocks[row].block.items[
                item
              ].subItems.push(current);
            }
            break;
          default:
            //@ts-ignore
            newSchema[mainblockIndex].mainBlock.blocks[row].block.items[
              item
            ].subItems[subItemPosition] = current;
            break;
        }
      }

      setSchema([...newSchema]);
    }
  };

  const cop = (
    mainblockIndex: any,
    index2: number,
    index: number,
    items?: any
  ) => {
    return (
      <ColumnOperations
        onShrink={() => {
          operateColumnTemplate(mainblockIndex, index2, index, false);
        }}
        onExpand={() => {
          operateColumnTemplate(mainblockIndex, index2, index, true);
        }}
        onDelete={() => {
          deleteItem(mainblockIndex, index2, index);
        }}
        toLeft={() => {
          toLeft(mainblockIndex, index2, index);
        }}
        toRight={() => {
          toRight(mainblockIndex, index2, index);
        }}
        onAdd={() => {
          onAdd(mainblockIndex, index2, index);
        }}
        canLeft={index > 0}
        canRight={index < items?.items.length - 1}
      />
    );
  };

  const cop2 = (
    mainblockIndex: any,
    index2: number,
    index: number,
    items: any,
    indexEl: number
  ) => {
    return (
      <ColumnOperations
        onDelete={() => {
          deleteSubItem(mainblockIndex, index2, index, indexEl);
        }}
        onAdd={() => {
          onEditSubItem(mainblockIndex, index2, index, indexEl);
        }}
      />
    );
  };

  React.useEffect(() => {
    if (inital == true && props.emit) {
      props.emit(schema);
    }
    if (inital == false) {
      setIntial(true);
    }
  }, [schema]);

  const repeatSubBlock = (mainblockIndex: number, index2: number) => {
    const newSchema = cloneDeep(schema);
    if (newSchema[mainblockIndex].mainBlock.blocks[index2]?.multi) {
      newSchema[mainblockIndex].mainBlock.blocks[index2].multi =
        !newSchema[mainblockIndex].mainBlock.blocks[index2].multi;
    } else {
      newSchema[mainblockIndex].mainBlock.blocks[index2].multi = true;
    }

    setSchema([...newSchema]);
  };

  const renderBlock = (
    items: any,
    mainblockIndex: number,
    index2: number
  ): React.ReactNode => {
    return (
      <>
        {items?.multi && <div>---</div>}
        <div className="flex items-start" key={index2.toString()}>
          <div style={{ width: "100%", ...items?.sx }}>
            {items?.items.map((item: any, index: number) => {
              return (
                <div key={index.toString()}>
                  <div style={{ display: "flex", alignItems: "center" }}></div>
                  <div style={{ width: "100%" }} key={index.toString()}>
                    {item?.input &&
                      React.createElement(
                        Components[item.input as string].component,
                        {
                          key: index.toString(),
                          id: item.name,
                          value: item.value,
                          label: item.label,
                          hidelabel: true.toString(),
                          options: item?.options,
                          endpoint: item?.endpoint,
                          labelendpoint: item?.labelEndpoint,
                          valueendpoint: item?.valueEndpoint,

                          action: cop(mainblockIndex, index2, index, items),
                        }
                      )}
                    <>
                      {item?.subItems &&
                        item?.subItems.map((el, indexEl) => {
                          return (
                            el?.input &&
                            React.createElement(
                              Components[el.input as string].component,
                              {
                                key: index.toString(),
                                id: el.name,
                                value: el.value,
                                label: el.label,
                                hidelabel: true.toString(),
                                options: el?.options,
                                endpoint: el?.endpoint,
                                labelendpoint: el?.labelEndpoint,
                                valueendpoint: el?.valueEndpoint,
                                action: cop2(
                                  mainblockIndex,
                                  index2,
                                  index,
                                  items,
                                  indexEl
                                ),
                              }
                            )
                          );
                        })}
                    </>
                  </div>
                  <Button
                    onClick={() => {
                      addNextField(mainblockIndex, index2, index);
                    }}
                    variant="outline"
                    className="my-2"
                    size="xs"
                  >
                    <Plus size={"12px"} />
                  </Button>
                </div>
              );
            })}
          </div>
          <div className="grid gap-2">
            <div className="grid ml-5 items-end">
              <RowOperations
                showDown={
                  index2 !=
                    schema[mainblockIndex].mainBlock.blocks.length - 1 &&
                  schema[mainblockIndex].mainBlock.blocks.length > 1
                }
                showUp={index2 !== 0}
                onAdd={() => {
                  addColumn(mainblockIndex, index2);
                }}
                onUp={() => {
                  upRow(mainblockIndex, index2);
                }}
                onDelete={() => {
                  deleteRow(mainblockIndex, index2);
                }}
                onDown={() => {
                  downRow(mainblockIndex, index2);
                }}
              />
            </div>{" "}
            <Button
              onClick={() => {
                repeatSubBlock(mainblockIndex, index2);
              }}
              variant={
                schema[mainblockIndex].mainBlock.blocks[index2]?.multi
                  ? "selected"
                  : "outline"
              }
              size="sm"
              className="max-w-[40px] ml-5"
            >
              <SquareStack size={"15px"} />
            </Button>
          </div>
        </div>
        {items?.block && renderBlock(mainblockIndex, items.block, index2)}
      </>
    );
  };
  const setMultipleMainBlock = (value: boolean, index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[index].mainBlock.multiple = value;
    setSchema([...newSchema]);
  };

  const setFlodMainBlock = (value: boolean, index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[index].mainBlock.flodAble = value;
    setSchema([...newSchema]);
  };
  const setName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newSchema = cloneDeep(schema);
    newSchema[index].mainBlock.name = event.target.value;
    setSchema([...newSchema]);
  };

  const setCopyFrom = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newSchema = cloneDeep(schema);
    newSchema[index].mainBlock.copyFrom = event.target.value;
    setSchema([...newSchema]);
  };
  const setdisplayName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newSchema = cloneDeep(schema);
    newSchema[index].mainBlock.displayName = event.target.value;
    setSchema([...newSchema]);
  };

  const addRow = (index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[index].mainBlock.blocks.push(newRow);
    setSchema([...newSchema]);
  };

  const addBlock = () => {
    const newSchema = cloneDeep(schema) || [];
    newSchema.push(newMainBlock);

    setSchema([...newSchema]);
  };

  const mainBlockDown = (index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[index + 1] = newSchema.splice(
      index,
      index + 1,
      newSchema[index + 1]
    )[0];

    setSchema([...newSchema]);
  };
  const mainBlockUp = (index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema[index] = newSchema.splice(index - 1, 1, newSchema[index])[0];

    setSchema([...newSchema]);
  };
  const mainBlockDelete = (index: number) => {
    const newSchema = cloneDeep(schema);
    newSchema.splice(index, 1);

    setSchema([...newSchema]);
  };

  const [openImportDialog, setOpenImportDialog] = React.useState(false);
  const [choosenFragment, setChoosenFragment] = React.useState<string>("");

  const { data: dataFragment, refetch } = usePgQuery({ pageType: "fragment" });

  const addFragment = () => {
    const fragmentSchema = dataFragment?.find(
      (item: any) => item._id.$oid === choosenFragment
    )?.pgSchema;
    const copySchema = cloneDeep(schema);

    copySchema.push(...fragmentSchema);

    setSchema(copySchema);
    setOpenImportDialog(false);
  };

  const addNextField = (mainblockIndex, row, item) => {
    dispatchDialog({
      type: "open",
      payout: {
        subItem: true,
        mainblockIndex: mainblockIndex,
        row: row,
        item: item,
        field: schema[mainblockIndex].mainBlock.blocks[row].block.items[item],
      },
    });
  };

  return (
    <>
      <Dialog
        open={openImportDialog}
        onOpenChange={() => {
          setOpenImportDialog(false);
        }}
      >
        <DialogContent>
          <DialogTitle>Importuj fragment</DialogTitle>
          <Select onValueChange={(value) => setChoosenFragment(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Wybierz" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {dataFragment?.map((item: any) => {
                  return (
                    <SelectItem key={item._id.$oid} value={item._id.$oid}>
                      {" "}
                      {item.slug}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>

          <DialogFooter>
            <Button
              onClick={() => {
                setOpenImportDialog(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              onClick={() => {
                addFragment();
              }}
            >
              Zapisz
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AddFieldDialog
        field={addingDialogState.field}
        open={addingDialogState.dialogOpen}
        onClose={() => {
          dispatchDialog({ type: "close" });
        }}
        onSave={(el) => {
          save(el);
        }}
      />

      <div className="flex py-[2em] gap-2">
        <h4 style={{ marginBottom: "1em" }} className="text-2xl font-bold pb-5">
          Bloki i pola
        </h4>
        <div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  onClick={() => {
                    addBlock();
                  }}
                >
                  <Plus size={"1em"} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Dodaj Block</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  onClick={() => {
                    setOpenImportDialog(true);
                  }}
                >
                  <Import size={"1em"} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Importuj fragment</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      {schema?.map((mainblock: mainBlockType, index: number) => {
        return (
          <div
            key={index.toString()}
            className="border border-black border-dashed p-[8px] py-[16[px] mb-[16px]"
          >
            <div className="flex items-end justify-end gap-2 mb-5">
              <InputWithLabel
                id=""
                label="nazwa wyświetalana"
                value={mainblock.mainBlock.displayName}
                onChange={(event) => {
                  setdisplayName(event, index);
                }}
              />
              <InputWithLabel
                id=""
                label="nazwa systemowa"
                value={mainblock.mainBlock.name}
                onChange={(event) => {
                  setName(event, index);
                }}
              />
              <InputWithLabel
                id=""
                label="można kopiować z"
                value={mainblock.mainBlock.copyFrom}
                onChange={(event) => {
                  setCopyFrom(event, index);
                }}
              />
              <div className="grid">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant={
                          mainblock.mainBlock.flodAble ? "selected" : "outline"
                        }
                        onClick={() => {
                          setFlodMainBlock(
                            !mainblock.mainBlock.flodAble as boolean,
                            index
                          );
                        }}
                      >
                        <FoldVertical size={"1em"} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Zwijalny</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => {
                        addRow(index);
                      }}
                    >
                      <Import size={"1em"} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Dodaj wiersz</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              {index != 0 && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        onClick={() => {
                          mainBlockUp(index);
                        }}
                      >
                        <ChevronUp size={"1em"} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Przesuń w górę</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {schema.length - 1 != index && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        onClick={() => {
                          mainBlockDown(index);
                        }}
                      >
                        <ChevronDown size={"1em"} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Przesuń w dół</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => {
                        mainBlockDelete(index);
                      }}
                    >
                      <Trash2 size={"1em"} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Usuń</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <div className="grid">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant={
                          mainblock.mainBlock.multiple ? "selected" : "outline"
                        }
                        onClick={() => {
                          setMultipleMainBlock(
                            !mainblock.mainBlock.multiple as boolean,
                            index
                          );
                        }}
                      >
                        <SquareStack size={"1em"} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Powielanie</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            {mainblock.mainBlock.blocks.map((elx: rowType, index2: number) => {
              console.log(mainblock.mainBlock.blocks, "asdsd");
              return (
                <>
                  {elx.multi && (
                    <div>
                      <InputWithLabel
                        label="Nazwa wiersza"
                        value={elx?.subBlockName?.toString() || ""}
                        className="mb-2 max-w-[300px]"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const newSchema = cloneDeep(schema);
                          newSchema[index].mainBlock.blocks[
                            index2
                          ].subBlockName = event.target.value;
                          setSchema([...newSchema]);
                        }}
                      />
                    </div>
                  )}
                  <div key={index2.toString()}>
                    {renderBlock(elx.block, index, index2)}
                  </div>
                </>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
export default FormCreator;
