type LocalFunctionsRegister = {
    'click': string[],
    'blur': string[],
    'change': string[]
}

const processSystemFunc = (item: string): LocalFunctionsRegister => {

    let functions: string[] = []
    if(item){
        functions = item?.split(",")
    }


    const localFunctionsRegister: LocalFunctionsRegister = {
        'click': [],
        'blur': [],
        'change': []
    }

    functions?.forEach((el: string) => {
        const func = el.split(":")

        if (func.length === 2) {
            const [event, handler] = func;
            if (event in localFunctionsRegister) {
                localFunctionsRegister[event as keyof LocalFunctionsRegister].push(handler);
            }
        }

    })

    return localFunctionsRegister
}

export default processSystemFunc