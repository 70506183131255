import * as React from "react";

import { cn } from "../../lib/utils";
import { Label } from "./label";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputWithLabel = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    label: string;
    action?: any;
    value?: any;
    whiteLabel?: boolean;
  }
>(({ className, type, ...props }, ref) => {
  return (
    <div>
      <div className="flex gap-5">
        <Label htmlFor="email" className={props.whiteLabel ? "text-white" : ""}>
          {props?.label}
        </Label>
        {props?.action}
      </div>

      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input  px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 outline-none",
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});
InputWithLabel.displayName = "InputWidthLabel";

export { InputWithLabel };
