import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const statisticApi = createApi({
    reducerPath: 'statisticApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,

        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
            headers.set('Access-Control-Allow-Origin', '*')
            headers.set("Access-Control-Allow-Methods", "GET, PUT, POST, DELETE, OPTIONS")
            headers.set("WithCredentials", "true");

            headers.set("Access-Control-Allow-Credentials", "true")

            if (token) {

             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['Statistic'],
    endpoints: (builder) => ({
        impreza: builder.query({
            query: () => {


                    return { url: '/statistic/impreza/', method: 'GET' }

            },

        }),
        turnus: builder.query({
            query: () => {


                    return { url: '/statistic/turnus/', method: 'GET' }

            },

        }),

    })
});

export const { useImprezaQuery,useTurnusQuery } = statisticApi;
