import React from "react";
import DataTable from "../../components/pageParts/table";
import ActionCell from "../../components/pageParts/ActionCell";
import {
  usePgQuery,
  useDeletePgMutation,
} from "../../services/pageGeneratorApi";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../../components/ui/button";

const PageGeneratorList = () => {
  const title = undefined;

  const { data, refetch } = usePgQuery(undefined);

  const [deletePg, response] = useDeletePgMutation();

  React.useEffect(() => {
    if (response.isSuccess) {
      refetch();
    }
  }, [response]);

  const headerCells = [
    {
      accessorKey: "objectId",
      header: "objectId",
      visibilty: false,
      fancyName: "objectId",
    },
    {
      accessorKey: "name",
      fancyName: "Nazwa",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Nazwa
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: "slug",
      fancyName: "Slug",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Slug
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: "pageType",
      fancyName: "Typ",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Typ
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },

    {
      accessorKey: "action",
      allwaysVisible: true,
      header: () => {
        <div className="flex justify-end">Akcje</div>;
      },

      cell: ({ row }: any) => {
        return (
          <div className="justify-end flex text-[0.em]">
            <ActionCell
              deleteAction={deletePg}
              editLink={"creator"}
              row={row}
            />
          </div>
        );
      },
    },
  ];

  const d = data?.map((el: any) => {
    return {
      objectId: el._id.$oid,
      name: el.name,
      slug: el.slug,
      pageType: el.pageType,
    };
  });

  const filters = [
    {
      field: "name",
      label: "Nazwa",
    },
    {
      field: "slug",
      label: "Slug",
    },
    {
      field: "pageType",
      label: "Typ",
    },
  ];

  return (
    <div>
      {d && (
        <DataTable
          columns={headerCells}
          data={d}
          filters={filters}
          label={"Lista schematów"}
          tableName="generator"
        />
      )}
    </div>
  );
};
export default PageGeneratorList;
