import { isFulfilled, isRejected } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'

import { openredirect } from '../store/redirect';
import { Cookies } from 'react-cookie';
import { store } from '../store';
import { toast } from '../components/ui/use-toast';


export const rtkQueryErrorLogger: Middleware =

  (api: MiddlewareAPI) => (next) => (action) => {



    if (isRejected(action)) {
      if (action.payload?.status == 'FETCH_ERROR') {
        toast({
          variant: "destructive",
          title: "Oj! Wystąpił błąd!",
          description: "Wystąpił błąd z połączeniem z serwerem! Spróbuj ponownie.",
        });
      }

    }


    if (isFulfilled(action)) {


      if (action.meta.arg.type === "mutation" && action?.payload?.status !== 'error') {
        toast({
          variant: "success",
          title: "Super!",
          description: "Wszytkie dane zostały zapisane!",
        });
      }

      if (action.meta.arg.type === "mutation" && action?.payload?.status === 'error') {
        toast({
          variant: "destructive",
          title: "Oj! Wystąpił błąd!",
          description: "Wystąpił błąd z zapisem danych! Spróbuj ponownie.",
        });
      }
    }





    // if (isRejected(action) && action?.error?.name!=='ConditionError') {

    //   if (action.payload?.data?.title === '401 Unauthorized')
    //   {
    //     api.dispatch(openredirect({isAuth:false}))
    //     }
    //   else {
    //     api.dispatch(openredirect({isAuth:true}))
    //   }

    //   api.dispatch(openToast({ message: 'wstąpił błąd \n - '+action?.payload?.data?.title || "", type:'error'}))

    // }



    return next(action)
  }