import { Button, buttonVariants } from "../../components/ui/button";
import { Pencil, Trash2 } from "lucide-react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { NavLink } from "react-router-dom";
const ActionCell = ({ children, deleteAction, editLink, row }: any) => {
  return (
    <>
      {children}
      {deleteAction && (
        <div>
          <TooltipProvider>
            <Tooltip delayDuration={2}>
              <TooltipTrigger>
                <Popover>
                  <PopoverTrigger asChild={true}>
                    <div className={buttonVariants({ variant: "ghost" })}>
                      <Trash2 size={"1em"} />
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto">
                    <div className="grid gap-5 justify-center">
                      Czy napewno chcesz
                      <br />
                      skasować tą pozycje ?
                      <Button
                        onClick={() => {
                          deleteAction(row.getValue("objectId"));
                        }}
                      >
                        Usuń{" "}
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </TooltipTrigger>
              <TooltipContent>
                <p>Usuń</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>{" "}
        </div>
      )}
      <div>
        <TooltipProvider>
          <Tooltip delayDuration={2}>
            <TooltipTrigger>
              <NavLink to={`/${editLink}/${row.getValue("objectId")}`}>
                <div className={buttonVariants({ variant: "ghost" })}>
                  <Pencil size={"1em"} />
                </div>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent>
              <p>Edytuj</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </>
  );
};

export default ActionCell;
