import { configureStore, createImmutableStateInvariantMiddleware } from '@reduxjs/toolkit';
import { pgApi } from '../services/pageGeneratorApi';
import { pageApi } from '../services/pageCreateApi';
import { mediaDirsApi } from '../services/mediaDirsApi'
import { mediaApi } from '../services/mediaApi'
import menu from './menu';
import toast from './toast';
import { rtkQueryErrorLogger } from '../middleware/toast'
import { pageListApi } from '../services/pageListApi';
import { turnusApi } from '../services/turnusApi'
import { userApi } from '../services/auth'
import { bookingApi } from '../services/bookingApi'
import authReducer from './authSlice'
import redirect from './redirect';
import { documentApi } from '../services/documentApi';
import { documentStaticApi } from '../services/documentStaticApi';
import { mailTemplateApi } from '../services/mailTemplateApi';
import { jachtApi } from '../services/jachtApi';
import { skiperApi } from '../services/skiperApi';
import { automaticRulesApi } from '../services/automaticRulesApi';
import { actionApi } from '../services/actionApi';
import { cuponsApi } from '../services/cuponsApi';
import { isAuth } from '../middleware/isAuth'
import { turnusItemApi } from '../services/turnusItemApi';
import { statisticApi } from '../services/statisticApi';
import { configApi } from '../services/configApi'
import { profileApi } from '../services/profileApi'
import { bookingOperationApi } from '../services/bookingOperation';
import { webMenuApi } from '../services/menuApi';
import { statusApi } from '../services/statusApi';
import { unSeenApi } from '../services/unSeeanApi';
import { imprezaApi } from '../services/imprezaApi';
import { bookingOthersApi } from '../services/bookingOthersApi';
import currentRowId from './currentRowId';
import galleryState from './gallery';
import { extramenuApi } from '../services/extramenuApi';
import { collectionApi } from '../services/collectionApi';
import { newConfigApi } from '../services/newConfigApi'
import { orderApi } from '../services/orderApi';
import { statusesApi } from '../services/statusesApi';
import { massPromoApi } from '../services/masspromoApi';
import { karnetyApi } from '../services/karnetyApi';


const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
    ignoredPaths: ['login'],
})


export const store = configureStore({
    reducer: {
        [karnetyApi.reducerPath]: karnetyApi.reducer,
        [massPromoApi.reducerPath]: massPromoApi.reducer,
        [statusesApi.reducerPath]: statusesApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [newConfigApi.reducerPath]: newConfigApi.reducer,
        [collectionApi.reducerPath]: collectionApi.reducer,
        [documentApi.reducerPath]: documentApi.reducer,
        [documentStaticApi.reducerPath]: documentStaticApi.reducer,
        [pgApi.reducerPath]: pgApi.reducer,
        [pageApi.reducerPath]: pageApi.reducer,
        [mediaDirsApi.reducerPath]: mediaDirsApi.reducer,
        [mediaApi.reducerPath]: mediaApi.reducer,
        [pageListApi.reducerPath]: pageListApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [turnusApi.reducerPath]: turnusApi.reducer,
        [bookingApi.reducerPath]: bookingApi.reducer,
        [mailTemplateApi.reducerPath]: mailTemplateApi.reducer,
        [jachtApi.reducerPath]: jachtApi.reducer,
        [skiperApi.reducerPath]: skiperApi.reducer,
        [automaticRulesApi.reducerPath]: automaticRulesApi.reducer,
        [actionApi.reducerPath]: actionApi.reducer,
        [cuponsApi.reducerPath]: cuponsApi.reducer,
        [turnusItemApi.reducerPath]: turnusItemApi.reducer,
        [statisticApi.reducerPath]: statisticApi.reducer,
        [configApi.reducerPath]: configApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [bookingOperationApi.reducerPath]: bookingOperationApi.reducer,
        [webMenuApi.reducerPath]: webMenuApi.reducer,
        [statusApi.reducerPath]: statusApi.reducer,
        [unSeenApi.reducerPath]: unSeenApi.reducer,
        [imprezaApi.reducerPath]: imprezaApi.reducer,
        [bookingOthersApi.reducerPath]: bookingOthersApi.reducer,
        [extramenuApi.reducerPath]: extramenuApi.reducer,
        menu: menu.reducer,
        toast: toast.reducer,
        redirect: redirect.reducer,
        auth: authReducer,
        currentRowId: currentRowId.reducer,
        galleryState: galleryState.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(isAuth)
        .concat([
            karnetyApi.middleware,
            massPromoApi.middleware,
            statusesApi.middleware,
            orderApi.middleware,
            newConfigApi.middleware,
            collectionApi.middleware,
            mailTemplateApi.middleware,
            userApi.middleware,
            mediaApi.middleware,
            pgApi.middleware,
            documentApi.middleware,
            documentStaticApi.middleware,
            pageApi.middleware,
            pageListApi.middleware,
            turnusApi.middleware,
            bookingApi.middleware,
            mediaDirsApi.middleware,
            skiperApi.middleware,
            jachtApi.middleware,
            automaticRulesApi.middleware,
            actionApi.middleware,
            cuponsApi.middleware,
            extramenuApi.middleware,
            turnusItemApi.middleware,
            statisticApi.middleware,
            configApi.middleware,
            profileApi.middleware,
            bookingOperationApi.middleware,
            webMenuApi.middleware,
            statusApi.middleware,
            unSeenApi.middleware,
            imprezaApi.middleware,
            bookingOthersApi.middleware,


        ])
        .concat(rtkQueryErrorLogger).concat(immutableInvariantMiddleware)
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
