import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const configApi = createApi({
    reducerPath: 'configApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['config'],
    endpoints: (builder) => ({
        config: builder.query({
            query: () => {

                    return { url: '/config/', method: 'GET' }

            },

        }),
        addConfig: builder.mutation({
            query: (page) => ({
                url: '/config/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['config']
        }),

    })
});

export const { useConfigQuery, useAddConfigMutation} = configApi;
