import { EDITOR_JS_TOOLS } from "./tools/tools";
import EditorJs from "@natterstefan/react-editor-js";

import voca from "voca";
import React, { Suspense } from "react";

import { uniqueId } from "lodash";
import { Label } from "./label";
import { Button } from "./button";
type UiEditorProps = {
  id?: string;
  value?: any;
  label?: string;
  defautl?: any;
  isError?: boolean;
  errorText?: string;
  onChange?: (value: any, index?: number) => void;
  onfocus?: (value: any) => void;
  onblur?: (value: any) => void;
  onClick?: (value: any) => void;
  enabled?: boolean;
  hideLabel?: boolean;
  options?: any;
  endpoint?: string;
  valueEndpoint?: string;
  labelEndpoint?: string;
  readonly?: boolean;
};
type editor = {
  isReady: any;
  save: () => any;
};

export const Editor = (props: UiEditorProps & { action: any }) => {
  const [w, setW] = React.useState("editor" + uniqueId().toString());
  // const [value, setValue] = React.useState(props.value);

  let editor = null as editor | null;

  const onReady = () => {
    // https://editorjs.io/configuration#editor-modifications-callback
    console.log("Editor.js is ready to work!");
  };

  const onChange = async () => {};

  const onSave = async () => {
    // https://editorjs.io/saving-data
    try {
      const outputData = await editor!.save();
      props?.onChange && props.onChange(outputData);
    } catch (e) {
      console.error("Saving failed: ", e);
    }
  };

  return (
    <>
      <div className="flex gap-5 items-center">
        <Label>{props.label}</Label>
        {props?.action}
      </div>

      <div
        style={{
          paddingLeft: "80px",
          border: "1px solid #00000026",
          borderRadius: "4px",
        }}
      >
        {/* docs: https://editorjs.io/configuration */}
        {!props.value && (
          <EditorJs
            data={{ blocks: [], time: 0, version: "2.22.2" } as any}
            holder={w}
            onReady={onReady}
            onChange={onChange}
            tools={EDITOR_JS_TOOLS}
            editorInstance={(editorInstance: editor) => {
              // invoked once the editorInstance is ready
              editor = editorInstance;
            }}
          >
            <div id={w} />
          </EditorJs>
        )}
        {props.value && (
          <EditorJs
            data={props.value as any}
            tools={EDITOR_JS_TOOLS}
            // will be `editorjs` by default
            holder={w}
            onReady={onReady}
            onChange={onChange}
            editorInstance={(editorInstance) => {
              // invoked once the editorInstance is ready
              editor = editorInstance;
            }}
          >
            <div id={w} />
          </EditorJs>
        )}
        {/* <FormHelperText error={props?.isError}>{props?.errorText}</FormHelperText> */}
        <div
          style={{ display: "flex", justifyContent: "right", padding: "10px" }}
        >
          <Button
            style={{ fontSize: "12px", textTransform: "lowercase" }}
            onClick={onSave}
          >
            Zapisz tekst
          </Button>
        </div>
      </div>
    </>
  );
};
