// import Embed from '@editorjs/embed';
// @ts-ignore
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';
import NestedList from '@editorjs/nested-list';
import Paragraph from 'editorjs-paragraph-with-alignment'
import Underline from '@editorjs/underline';
// next define the tools in the main block
// Warning - Dont just use main_tools - you will probably generate a circular reference
// import MarkerTool from './testPlugin';
export const EDITOR_JS_TOOLS = {
    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
    paragraph: {
        class: Paragraph,
        inlineToolbar: true,
        config: {
            placeholder: 'Zacznij pisać...'
          }
    },
    // embed: Embed,
    //  myMarker: MarkerTool,
    table: Table as any,
    list: List,
    code: Code,
    linkTool: LinkTool,
    image: Image,
    raw: Raw,
    header: Header,
    quote: Quote,
    marker: Marker,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
    nestedList: NestedList,
    underline: Underline
};
