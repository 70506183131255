import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const cuponsApi = createApi({
    reducerPath: 'cuponsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['cupons'],
    endpoints: (builder) => ({
        cupons: builder.query({
            query: (arg) => {


                    return { url: '/cupons/', method: 'GET' }

            },

        }),
        addCupons: builder.mutation({
            query: (page) => ({
                url: '/cupons/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['cupons']
        }),
        updateCupons: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/cupons/`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['cupons']
        }),

    })
});

export const { useCuponsQuery, useAddCuponsMutation, useUpdateCuponsMutation } = cuponsApi;
