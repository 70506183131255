import { IMenuItem } from "./types";
import { NavLink } from "react-router-dom";
import Icon from "./icon";

const MenuItem = ({ ...props }: IMenuItem) => {
  return (
    <li className="py-2 text-[0.9em] text-black hover:text-black cursor-pointer">
      <NavLink
        to={props.url}
        className="flex items-center opacity-100  capitalize  lowercase"
      >
        <Icon name={props.icon} color="black" size={"0.7em"} noBorder={true} />
        {props.name}
      </NavLink>
    </li>
  );
};

export default MenuItem;
