import * as React from "react";

import { cn } from "../../lib/utils";
import { Label } from "./label";
import { set } from "lodash";
import ts from "typescript";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const YTLink = React.forwardRef<
  HTMLInputElement,
  InputProps & { label: string; action?: any; value?: any }
>(({ className, type, ...props }, ref) => {
  console.log(props);
  const [value, setValue] = React.useState(props?.value || "");
  const transform = (value) => {
    try {
      const url = new URL(value);
      const searchParams = new URLSearchParams(url.search);
      const v = searchParams.get("v");

      return v;
    } catch (error) {
      return value;
    }
  };

  const propsWithoutValue = { ...props };
  delete propsWithoutValue.value;
  delete propsWithoutValue.onChange;

  return (
    <div>
      <div className="flex gap-5">
        <Label htmlFor="email">{props?.label}</Label>
        {props?.action}
      </div>

      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 outline-none",
          className
        )}
        ref={ref}
        {...propsWithoutValue}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={(e) => {
          const tr = transform(e.target.value) || "";
          setValue(tr);
          props?.onChange &&
            //@ts-ignore
            props?.onChange(tr);
        }}
      />
      <div className="py-5">
        <img
          src={`https://img.youtube.com/vi/${value}/0.jpg`}
          className="aspect-video max-w-[200px] object-cover rounded-md"
        />
      </div>
    </div>
  );
});
YTLink.displayName = "YTLink";

export { YTLink };
