import * as React from "react";

import { cn } from "../../lib/utils";
import { Label } from "./label";
import { EyeOff, Eye } from "lucide-react";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const PasswordWithLabel = React.forwardRef<
  HTMLInputElement,
  InputProps & { label: string; action?: any; whiteLabel?: boolean }
>(({ className, type, ...props }, ref) => {
  const [visibility, setVisibility] = React.useState<boolean>(false);
  return (
    <div>
      <div className="flex gap-5">
        <Label htmlFor="email" className={props.whiteLabel ? "text-white" : ""}>
          {props?.label}
        </Label>
        {props?.action}
      </div>
      <div
        className={cn(
          "flex w-full text-sm px-2 h-10 rounded-md border border-input",
          className
        )}
      >
        <input
          type={visibility ? "text" : "password"}
          className={cn(
            "flex-1 w-full h-full text-sm placeholder-text-muted-foreground bg-transparent outline-none",
            className
          )}
          ref={ref}
          {...props}
        />
        <div
          className="flex items-center"
          onClick={() => {
            setVisibility(!visibility);
          }}
        >
          {visibility ? <EyeOff size={"1em"} /> : <Eye size={"1em"} />}
        </div>
      </div>
    </div>
  );
});
PasswordWithLabel.displayName = "PassswordWithLabel";

export { PasswordWithLabel };
