import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { Cookies } from 'react-cookie';
type AuthState = {
  token: string | null,
  username: string | null,
  image: any,
  role: string | null
}

const slice = createSlice({
  name: 'auth',
  initialState: { username: null, token: null, image:null, role:null
} as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token, username,image, role } }: PayloadAction<{ token: string, username:string,image:any,role:string }>
    ) => {
      let cookie = new Cookies();
      cookie.set('token', token, { path: '/', sameSite: true, secure: true });
      cookie.set('role', role, { path: '/', sameSite: true, secure: true })
      cookie.set('user', username, { path: '/', sameSite: true, secure: true });
      cookie.set('image', image, { path: '/', sameSite: true, secure: true });
      cookie.set('token2', token, { path: '/', sameSite:true, secure:true, httpOnly:true});

    },
  },
})

export const { setCredentials } = slice.actions

export default slice.reducer

// export const selectCurrentUser = (state: RootState) => state.auth.user
