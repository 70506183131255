import { cloneDeep } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import EnhancedFrom, {
  ImperativeHandle,
} from "../../components/form/EnhancedFrom";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import {
  useAddPageMutation,
  usePageQuery,
  useUpdatePageMutation,
} from "../../services/pageCreateApi";
import { usePgQuery } from "../../services/pageGeneratorApi";
type CreatePageProps = {};

const CreatePage = (props: CreatePageProps) => {
  const params = useParams();

  const [schema, setSchema] = React.useState<any | undefined>(undefined);

  const [defaultValues, setDefault] = React.useState<any[]>([]);

  const { data: page_data } = usePageQuery(params.id, {
    skip: params.id == undefined,
  });

  const { data: schema_data } = usePgQuery(page_data?.[0]?.schema_id, {
    skip: !page_data?.[0]?.schema_id,
  });

  const { data: mainPageSchema } = usePgQuery({ slug: "mainpage" });

  const [updatePage] = useUpdatePageMutation();
  const [addPage, result] = useAddPageMutation();

  React.useEffect(() => {
    if (schema_data && mainPageSchema && mainPageSchema[0]) {
      let copy_schema_data = cloneDeep(schema_data);
      for (let i = mainPageSchema[0].pgSchema.length - 1; i >= 0; i--) {
        copy_schema_data[0].pgSchema.splice(
          0,
          0,
          mainPageSchema[0].pgSchema[i]
        );
      }
      setSchema(copy_schema_data);
    }
    // if(mainPageSchema && ){
    //     setDefault([mainPageSchema[0].blocks])
    // }
  }, [schema_data, mainPageSchema]);

  React.useEffect(() => {
    if (page_data !== undefined && page_data[0] !== undefined) {
      setDefault([page_data[0].blocks]);
    }
  }, [page_data]);

  const save = (values: any) => {
    let page: any = {};
    page.schema_id = params.pageSchema;
    page.blocks = values;

    if (params.id !== undefined) {
      page.blocks = page.blocks;
      updatePage({ id: params.id, rest: page });
    } else {
      addPage(page);
    }
  };

  const formRef = React.useRef<ImperativeHandle>(null);

  return (
    <Card>
      {schema && (
        <EnhancedFrom
          ref={formRef}
          values={defaultValues}
          schema={schema[0].pgSchema}
          onSave={(values: any) => {
            save(values);
          }}
          saveButton={false}
          inDialog={false}
        />
      )}

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ marginRight: "8px", marginTop: "2em" }}
          onClick={() => {
            formRef?.current?.saveEmit();
          }}
        >
          zapisz
        </Button>
      </div>
    </Card>
  );
};
export default CreatePage;
