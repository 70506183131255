import { RowOperationsProps } from "../../types/types";

import {
  ChevronDown,
  ChevronUp,
  MoreVertical,
  Plus,
  Trash2,
} from "lucide-react";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarTrigger,
} from "../menubar";

const RowOperations = (props: RowOperationsProps) => {
  return (
    <div>
      <Menubar>
        <MenubarMenu>
          <MenubarTrigger className="border-0 w-[40px]">
            <MoreVertical size={"15px"} />
          </MenubarTrigger>
          <MenubarContent>
            <MenubarItem
              disabled={!props.showUp}
              onClick={() => {
                props.showDown && props.onUp();
              }}
            >
              Przesuń w górę
              <MenubarShortcut>
                <ChevronUp size={"15px"} />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              disabled={!props.showDown}
              onClick={() => {
                props.showDown && props.onDown();
              }}
            >
              Przesuń w dół
              <MenubarShortcut>
                <ChevronDown size={"15px"} />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarSeparator />
            <MenubarSub>
              <MenubarItem onClick={props.onAdd}>
                Dodaj kolumnę{" "}
                <MenubarShortcut>
                  <Plus size={"15px"} />
                </MenubarShortcut>
              </MenubarItem>
              <MenubarItem onClick={props.onDelete}>
                Usuń wiersz
                <MenubarShortcut>
                  <Trash2 size={"15px"} />
                </MenubarShortcut>
              </MenubarItem>
            </MenubarSub>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </div>
  );
};
export default RowOperations;
