import { AspectRatio } from "./aspect-ratio";
import { Check } from "lucide-react";

type Props = {
  children: React.ReactNode;
  _id: { $oid: string };
  oid?: boolean;
  selected?: { _id: string }[] | { _id: string };
  showSelected?: boolean;
  w?: string;
  filename?: string;
};

export const ImagePlaceHolder = ({
  children,
  _id,
  oid,
  selected,
  showSelected,
  w,
  filename,
  ...rest
}: Props) => {
  const isSelected =
    selected &&
    (_isArray(selected)
      ? selected.some((item) => item._id === _id.$oid)
      : selected._id === _id.$oid);

  return (
    <div>
      <div
        className={`block min-w-[200px] rounded-sm p-[10px] relative ${w} cursor-pointer`}
        {...rest}
      >
        {isSelected && showSelected && (
          <div className="absolute top-0 right-0 z-[5] flex bg-black text-white rounded-full p-[4px] ">
            <Check size={"1em"} />
          </div>
        )}

        <AspectRatio ratio={1 / 1}>
          {" "}
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {children}
          </div>
        </AspectRatio>
        <div className="text-center">
          <small>{filename}</small>
        </div>
      </div>
    </div>
  );
};

function _isArray(value: any): value is any[] {
  return Array.isArray(value);
}
