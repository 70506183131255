import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { pl } from "date-fns/locale";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Label } from "./label";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export const DatePicker = React.forwardRef<
  React.ElementRef<any>,
  React.ComponentPropsWithoutRef<any> & { label: string }
>(({ className, ...props }, ref) => {
  const [date, setDate] = React.useState<Date | null | undefined>();

  const handleDateChange = (date: Date | undefined | null) => {
    setDate(date);
    props.onChange(date);
  };

  return (
    <>
      <Label>{props?.label}</Label>
      <br />
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "w-[280px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? (
              format(date, "yyyy-MM-dd", { locale: pl })
            ) : (
              <span>
                {props?.value
                  ? format(new Date(props.value), "yyyy-MM-dd") || ""
                  : "wybierz datę"}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date || undefined}
            onSelect={(value) => {
              handleDateChange(value);
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </>
  );
});
