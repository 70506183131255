import React from "react";
import DataTable from "../../components/pageParts/table";
import ActionCell from "../../components/pageParts/ActionCell";
import { useDeletePageMutation } from "../../services/pageCreateApi";
import { usePageListQuery } from "../../services/pageListApi";
import { SlidersHorizontal } from "lucide-react";

import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { fragmentTypes } from "../../lib/fragmentTypes";
import { CheckboxWithLabel } from "../../components/ui/checkboxWithLabel";

const PageCreateList = () => {
  const navigate = useNavigate();
  const title = undefined;

  const { refetch, data } = usePageListQuery(undefined);
  const [deletePage, response] = useDeletePageMutation();

  const [pageType, setPageType] = React.useState({});

  const [ft, setFragmentType] = React.useState({});
  const [pages, setPages] = React.useState([]);

  const setInitPageType = () => {
    const items =
      localStorage.getItem("pageTypToDisplay") &&
      JSON.parse(localStorage.getItem("pageTypToDisplay") as string);
    setPageType(items);
    if (items) {
      setPageType(items);
    } else {
      const _pt = {};
      Object.keys(fragmentTypes).map((el) => {
        _pt[el] = false;
      });
      _pt["page"] = true;
      delete _pt["system"];
      setPageType(_pt);
    }
    const _ft = fragmentTypes;
    delete _ft["system"];
    setFragmentType(_ft);
  };

  React.useEffect(() => {
    setInitPageType();
  }, []);

  //refresh data on page enter
  React.useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    if (response.isSuccess) {
      refetch();
    }
  }, [response]);

  const headerCells = [
    {
      accessorKey: "objectId",
      header: "objectId",
      visibilty: false,
      fancyName: "objectId",
    },
    {
      accessorKey: "title",
      header: "Tytuł",
      fancyName: "Tytył",
    },
    {
      accessorKey: "slug",
      header: "Slug",
      fancyName: "Slug",
    },
    {
      accessorKey: "pageType",
      header: "Typ",
      fancyName: "Typ",
    },

    {
      accessorKey: "action",
      allwaysVisible: true,
      fancyName: "objectId",
      header: () => {
        <div className="flex justify-end">Akcje</div>;
      },

      cell: ({ row }: any) => {
        return (
          <div className="justify-end flex text-[0.em]">
            <ActionCell deleteAction={deletePage} editLink={"page"} row={row} />
          </div>
        );
      },
    },
  ];

  const d = pages?.map((el: any) => {
    return {
      objectId: el._id.$oid,
      title: el.title,
      slug: el.slug,
      pageType: el.pageType,
    };
  });

  const filters = [
    {
      field: "title",
      label: "Tytuł",
    },
    {
      field: "slug",
      label: "Slug",
    },
    {
      field: "pageType",
      label: "Typ",
    },
  ];

  React.useEffect(() => {
    let _pages = [];
    const _selected = Object.keys(pageType).filter((el) => {
      return pageType[el] == true;
    });

    _pages = data && data.filter((el: any) => _selected.includes(el.pageType));

    setPages(_pages);
  }, [pageType, data]);

  const action = (
    <Dialog>
      <DialogTrigger
        asChild
        onClick={() => {
          setInitPageType();
        }}
      >
        <Button variant={"ghost"} size={"sm"}>
          <SlidersHorizontal size={"15px"} />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Dodaj stronę</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4 py-4">
          {Object.keys(ft).map((el) => {
            return (
              <CheckboxWithLabel
                label={ft[el]}
                key={el}
                onCheckedChange={(e) => {
                  localStorage.setItem(
                    "pageTypToDisplay",
                    JSON.stringify({ ...pageType, [el]: e })
                  );
                  setPageType({ ...pageType, [el]: e });
                }}
                checked={pageType[el]}
              />
            );
          })}
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );

  return (
    <div>
      {d && (
        <DataTable
          columns={headerCells}
          data={d}
          label="Lista stron"
          filters={filters}
          extraActions={action}
          tableName="pages"
        />
      )}
    </div>
  );
};
export default PageCreateList;
