// ==============================|| THEME CONFIG  ||============================== //

type configType = { [key: string]: any }

const config: configType = {
    defaultPath: '/dashboard/default',
    fontFamily: `'Plus Jakarta Sans', sans-serif`,
    i18n: 'pl',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';
export const url = "https://backkijek.koniar3.usermd.net/"
export const serviceUrl = `${url}api`
export const serviceUrlFile = `https://backkijek.koniar3.usermd.net/media/serveInFly/`
