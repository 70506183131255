// types
import { createSlice } from '@reduxjs/toolkit';

type toastType = {
    isToastOpen?: boolean;
    message?: string;
    type?:string;
};
// initial state
const initialState:toastType = {
    isToastOpen: false,
    message: '',
    type:''
};

// ==============================|| SLICE - MENU ||============================== //

const toast = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        openToast(state:toastType,action?:any,type?:any) {
            state.isToastOpen = true;
            state.message = action?.payload?.message;
            state.type = action?.payload?.type;
        },

        closeToast(state: any) {
            state.isToastOpen = false;

        },


    }
});
export default toast
export const { openToast,  closeToast } = toast.actions;
