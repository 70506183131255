import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export const stringToSx = (styleString: string):{ [key: string]: string } => {
  const style = styleString.split(",")
  let sx: { [key: string]: string } = {};

  style.forEach((sxElement: string) => {
      const sxSplited: string[] = sxElement.split(":")
      sx[sxSplited[0] as string] = sxSplited[1]

  })



  return sx;
}