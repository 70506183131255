import { AspectRatio } from "./aspect-ratio";
import { FolderClosed, FolderOpen } from "lucide-react";
export const DirImage = (props: any) => {
  return (
    <div className="h-[150px] w-[150px] object-cover rounded-sm flex flex-col items-center text-center justify-center border-sm border mr-10 cursor-pointer">
      {props.name != props.currentDir && (
        <FolderClosed size={"2em"} className="" />
      )}
      {props.name == props.currentDir && (
        <FolderOpen size={"2em"} className="" />
      )}
      <div className="py-2">
        {props.name.length > 20
          ? props.name.substring(0, 20) + "..."
          : props.name}
      </div>
    </div>
  );
};
