import Menu from "../menu/menu";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ScrollArea } from "../ui/scroll-area";
import { Toaster } from "../ui/toaster";

const Sidebar = () => {
  return (
    <aside
      className="bg-white text-black p-2 min-h-screen h-full min-w-[250px] sticky   rounded-r-2xl "
      style={{ top: 0 }}
    >
      {/* <img
        src="./../logo.png"
        className="w-[80px] h-[80px] mx-auto mb-5 object-contain"
      /> */}
      <ScrollArea className="h-[80vh]   p-4">
        <Menu />
      </ScrollArea>
      <Toaster />
    </aside>
  );
};
export default Sidebar;
