import React, { Reducer } from "react";
import { Card } from "../../components/ui/card";

import FormCreator from "../../components/ui/formCreator";

import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import {
  useAddPgMutation,
  useUpdatePgMutation,
  usePgQuery,
} from "../../services/pageGeneratorApi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InputWithLabel } from "../../components/ui/inputWithLabel";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { CheckboxWithLabel } from "../../components/ui/checkboxWithLabel";

import { icons } from "../../lib/iconsList";
import Icon from "../../components/menu/icon";
import { fragmentTypes } from "../../lib/fragmentTypes";
const PageGenrator = () => {
  const [addPg, result] = useAddPgMutation();
  const [updatePg] = useUpdatePgMutation();

  const id = React.useRef<string | undefined>(undefined);

  let params = useParams();

  React.useEffect(() => {
    id.current = params.id;
  }, []);

  React.useEffect(() => {
    if (id.current == undefined) {
      id.current = result?.data?._id?.$oid;
    }
  }, [result]);

  type pgAction = {
    type:
      | "slug"
      | "name"
      | "pageType"
      | "pgSchema"
      | "clear"
      | "full"
      | "menuName"
      | "menuIcon"
      | "protected";
    payload?: any;
  };
  type pgState = {
    slug?: string;
    name?: string;
    pageType: string;
    protected?: boolean;
    pgSchema?: object[];
    menuName?: string;
    menuIcon?: string;
  };

  const { data } = usePgQuery(params?.id, {
    skip: params?.id ? false : true,
  });

  let initalPg: pgState = { pageType: "page" };

  const pgReducer = (state: pgState, action: pgAction): pgState => {
    const { type, payload } = { ...action };

    switch (true) {
      case type != "clear" && type !== "full":
        return { ...state, [type]: payload };
      case type === "full":
        return {
          ...state,
          slug: payload?.slug,
          name: payload?.name,
          protected: payload?.protected,
          pageType: payload?.pageType,
          pgSchema: payload?.schema,
          menuIcon: payload?.menuIcon,
          menuName: payload?.menuName,
        };
      case type == "clear":
        return initalPg;
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = React.useReducer<Reducer<pgState, pgAction>>(
    pgReducer,
    initalPg
  );
  const [pgSchemaInital, setPgInital] = React.useState<any>(undefined);

  React.useEffect(() => {
    if (data?.length > 0) {
      setPgInital(data[0].pgSchema);
      const w = data.map((el: pgState) => {
        return {
          slug: el.slug,
          name: el.name,
          protected: el.protected,
          pageType: el.pageType,
          schema: el.pgSchema,
          menuIcon: el.menuIcon,
          menuName: el.menuName,
        };
      })[0];

      dispatch({ type: "full", payload: w });
    }
  }, [data]);

  const clear = () => {
    dispatch({ type: "clear" });
  };

  const save = () => {
    if (id.current == undefined) {
      addPg(state);
    } else {
      updatePg({ id: id.current, ...state });
    }
  };

  return (
    <>
      <Card>
        <h1 className="text-2xl font-bold pb-5">Dane główne</h1>
        <div style={{ display: "flex", gap: "1em" }}>
          <InputWithLabel
            label="slug"
            value={state.slug || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({ type: "slug", payload: event.target.value });
            }}
          />
          <InputWithLabel
            label="nazwa robocza"
            value={state.name || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({ type: "name", payload: event.target.value });
            }}
          />

          <CheckboxWithLabel
            label={"Chroniona"}
            checked={state?.protected || false}
            onCheckedChange={(value) => {
              dispatch({ type: "protected", payload: value });
            }}
          />

          <div>
            <Label>Typ</Label>
            <Select
              defaultValue={state.pageType}
              onValueChange={(value) => {
                dispatch({ type: "pageType", payload: value });
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Wybierz opcje" />
              </SelectTrigger>

              <SelectContent>
                {Object.keys(fragmentTypes).map((el) => {
                  return (
                    <SelectItem value={el} key={el}>
                      {fragmentTypes[el]}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>

          <InputWithLabel
            label="nazwa w menu"
            value={state.menuName || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({ type: "menuName", payload: event.target.value });
            }}
          />

          <div>
            <Label>Ikona w menu</Label>
            <Select
              value={state.menuIcon || ""}
              onValueChange={(value) => {
                dispatch({ type: "menuIcon", payload: value });
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="wybierz" />
              </SelectTrigger>
              <SelectContent>
                {Object.keys(icons).map((el, index) => {
                  return (
                    <SelectItem value={el}>
                      <Icon name={el} width={"14px"} />
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div style={{ marginTop: "2em" }}>
          <FormCreator
            schema={pgSchemaInital}
            emit={(schema) => {
              dispatch({ type: "pgSchema", payload: schema });
            }}
          />
        </div>
        <div
          style={{
            marginTop: "2em",
            marginBottom: "2em",
            display: "flex",
            gap: "1em",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              clear();
            }}
          >
            Czyść
          </Button>
          <Button
            onClick={() => {
              save();
            }}
          >
            Zapisz
          </Button>
        </div>
      </Card>
    </>
  );
};

export default PageGenrator;
