import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const extramenuApi = createApi({
    reducerPath: 'extramenuApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['extramenu'],
    endpoints: (builder) => ({
        extramenu: builder.query({
            query: () => {


                return { url: '/extraMenu/', method: 'GET' }

            },

        }),


    })
});

export const { useExtramenuQuery } = extramenuApi;
