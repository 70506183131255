
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EnhancedFrom from '../../components/form/EnhancedFrom';
import { Card } from '../../components/ui/card';
import { usePgQuery } from '../../services/pageGeneratorApi';
import { Button } from '../../components/ui/button';
import { useAddTurnusMutation, useTurnusQuery, useUpdateTurnusMutation } from '../../services/turnusApi';



import { ImperativeHandle } from "../../components/form/EnhancedFrom";
type CreatePageProps = {}
const TurnusCreate = (props: CreatePageProps) => {

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams()


    const [schema, setSchema] = React.useState<any | undefined>(undefined)

    const [defaultValues, setDefault] = React.useState<any[]>([])

    const [updateTurnus] = useUpdateTurnusMutation()
    const [addTurnus, result] = useAddTurnusMutation()

    const { data: mainPageSchema } = usePgQuery({ slug: 'turnus' })
    const { data: page_data, refetch } = useTurnusQuery(params?.id, { skip: params?.id?false:true})


    React.useEffect(() => {
        if (params?.id == undefined) {
            setDefault([])
        }

    }, [location])

    React.useEffect(() => {

        if (mainPageSchema) {

            setSchema(mainPageSchema)
        }

    }, [mainPageSchema])


    React.useEffect(() => {

        if (page_data) {


            setDefault([page_data[0].blocks])

        } else {
            setDefault([])
        }
    }, [page_data])



    React.useEffect(() => {

        if (result.data) {
            navigate(`/turnus/${result.data.id['$oid']}`)
        }
    }, [result.data])

    const save = (values: any) => {
            let page: any = {}
            page.schema_id = params.pageSchema
            page.blocks = values

            if (params.id !== undefined) {
                page.blocks=page.blocks
                updateTurnus({ id: params.id, rest: page })
            }else{
                addTurnus(page)
            }
    }

    const formRef = React.useRef<ImperativeHandle>(null);

    return (<Card >

        {schema && (
            <EnhancedFrom
                ref={formRef}
                values={defaultValues}
                schema={schema[0].pgSchema}
                onSave={(values: any) => {save(values)  }} saveButton={false} inDialog={false} />
        )}

        <div style={{ display: 'flex', justifyContent: 'end' }}>


            <Button style={{ marginRight: '8px', marginTop: '2em' }}
                onClick={() => {formRef?.current?.saveEmit()  }}>zapisz</Button>
            </div>
    </Card >)

}
export default TurnusCreate