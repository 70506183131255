import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const documentApi = createApi({
    reducerPath: 'dokumentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {
            const token = new Cookies().get('token');
           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['Dokument'],
    endpoints: (builder) => ({
        document: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/document/', method: 'GET' }
                } else {
                    return { url: `/document/${arg}`, method: 'GET' }
                }
            },

        }),
        addDocument: builder.mutation({
            query: (dokument) => ({
                url: '/document/',
                method: 'POST',
                body: dokument
            }),
            invalidatesTags: ['Dokument']
        }),
        updateDocument: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/document/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['Dokument']
        }),
        deleteDocument: builder.mutation({
            query: (id) => ({
                url: `/document/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Dokument']
        })
    })
});

export const { useDocumentQuery, useAddDocumentMutation, useUpdateDocumentMutation, useDeleteDocumentMutation } = documentApi;
