import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const automaticRulesApi = createApi({
    reducerPath: 'automaticRulesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
            headers.set('withCredentials', 'true')
           if (token) {
             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['automaticRules'],
    endpoints: (builder) => ({
        automaticRules: builder.query({
            query: (arg) => {


                    return { url: '/autoRules/', method: 'GET' }

            },

        }),
        addAutomaticRules: builder.mutation({
            query: (page) => ({
                url: '/autoRules/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['automaticRules']
        }),

        updateAutomaticRules: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/autoRules/`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['automaticRules']
        }),
        deleteAutomaticRules: builder.mutation({
            query: (id) => ({
                url: `/autoRules/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['automaticRules']
        })
    })
});

export const { useAutomaticRulesQuery, useAddAutomaticRulesMutation, useUpdateAutomaticRulesMutation, useDeleteAutomaticRulesMutation } = automaticRulesApi;
