import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const mediaDirsApi = createApi({
    reducerPath: 'mediaDirsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

     const token = new Cookies().get('token');
   if (token) {
     headers.set('authorization', `Bearer ${token}`)
   }
   return headers
 }}),
    tagTypes: ['mediaDirs'],
    endpoints: (builder) => ({
        mediaDirs: builder.query({
            query: () => ({
                url: '/mediaDirs/',
                    method: 'GET'

            }),
            providesTags:['mediaDirs']

        }),
        addMediaDirs: builder.mutation({
            query: (mediaDirs) => ({
                url: '/mediaDirs/',
                method: 'POST',
                body: mediaDirs
            }),
            invalidatesTags: ['mediaDirs']
        }),

        deleteMediaDirs: builder.mutation({
            query: (id) => ({
                url: `/mediaDirs/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['mediaDirs']
        })
    })
});

export const { useMediaDirsQuery, useAddMediaDirsMutation,  useDeleteMediaDirsMutation } = mediaDirsApi;
