import React from "react";
import DataTable from "../../components/pageParts/table";
import ActionCell from "../../components/pageParts/ActionCell";

import { useOrderQuery } from "../../services/orderApi";

import { useNavigate, useParams } from "react-router-dom";

const OrderList = () => {
  const navigate = useNavigate();
  const title = undefined;

  const { data } = useOrderQuery(undefined);

  const d = data?.map((el) => {
    return {
      ...el,
      objectId: el._id,
      data: new Date(el.dataZamowienia["$date"]).toLocaleDateString(),
    };
  });

  const headerCells = [
    {
      accessorKey: "objectId",
      header: "objectId",
      visibilty: false,
      fancyName: "objectId",
    },
    {
      accessorKey: "order_id",
      header: "Kod",
      fancyName: "Kod",
    },

    {
      accessorKey: "data",
      header: "Data",
      fancyName: "Data",
    },
    {
      accessorKey: "status",
      header: "Status",
      fancyName: "Status",
    },
    {
      accessorKey: "name",
      header: "Nazwisko",
      fancyName: "Nazwisko",
    },
    {
      accessorKey: "action",
      allwaysVisible: true,
      fancyName: "objectId",
      header: () => {
        <div className="flex justify-end">Akcje</div>;
      },

      cell: ({ row }: any) => {
        return (
          <div className="justify-end flex text-[0.em]">
            <ActionCell editLink={"order/edit"} row={row} />
          </div>
        );
      },
    },
  ];

  const filters = [
    {
      field: "order_id",
      label: "Nr zamówienia",
    },
    {
      field: "status",
      label: "Status",
    },
    {
      field: "name",
      label: "Nazwisko",
    },
  ];

  // React.useEffect(() => {
  //   if (result.isSuccess) {
  //     window.location.href = "/order/" + result.data?._id?.$oid;
  //   }
  // }, [result]);

  return (
    <div>
      {d && (
        <DataTable
          columns={headerCells}
          data={d}
          label="Zamówienia"
          filters={filters}
        />
      )}
    </div>
  );
};
export default OrderList;
