import React from "react";
// import SimpleTable from '../../templates/simpleTable'
import {
  useDocumentQuery,
  useDeleteDocumentMutation,
} from "../../services/documentApi";

import ActionCell from "../../components/pageParts/ActionCell";
import DataTable from "../../components/pageParts/table";

const DocumentsList = () => {
    const title = undefined;

    const { data, refetch } = useDocumentQuery(undefined);

    const [deleteDokument, response] = useDeleteDocumentMutation();

    React.useEffect(() => {
        if (response.isSuccess) {
            refetch();
        }
    }, [response]);

    const headerCells = [
        {
            accessorKey: "objectId",
            header: "objectId",
            visibilty: false,
            fancyName: 'objectId'
        },
        {
            accessorKey: "nazwa",
            header: "Nazwa",
            fancyName: 'Nazwa'
        },
        {
            accessorKey: "slug",
            header: "Slug",
            fancyName: 'Slug'
        },
        {
            accessorKey: "action",
            allwaysVisible: true,
            header: () => { <div className='flex justify-end'>Akcje</div> },


            cell: ({ row }: any) => {

                return (<div className="justify-end flex text-[0.em]">
<ActionCell deleteAction={deleteDokument} editLink={'document'} row={row} />

                </div>)
            }
        }

    ];


    const d = data?.map((el: any) => {

        return {
            objectId: el._id.$oid,
            nazwa: el.blocks.mainpage[0].title,
            slug: el.blocks.mainpage[0].slug,
        }
    })

    const filters = [
        {
        field: "nazwa",
            label: "Nazwa"
        },
        {
            field: "slug",
            label: "Slug"
        }



]



  return (
      <div>

          {d && <DataTable columns={headerCells} data={d} label="Lista generownych dokumentów" filters={filters}  />}

    </div>

  );
};
export default DocumentsList;
