import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie';

export const documentStaticApi = createApi({
    reducerPath: 'dokumentStaticApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {
            const token = new Cookies().get('token');
           if (token) {
               headers.set('authorization', `Bearer ${token}`)
            //    headers.set('content-type','multipart/form-data')
           }
           return headers
        }
    }),
    tagTypes: ['DokumentStatic'],
    endpoints: (builder) => ({
        documentStatic: builder.query({
            query: (arg) => {

                if(arg==undefined){
                    return { url: '/documentStatic/', method: 'GET' }
                } else {
                    return { url: `/documentStatic/${arg}`, method: 'GET' }
                }
            },

        }),
        addDocumentStatic: builder.mutation<{}, FormData>({
            query: (dokument) => ({
                url: '/documentStatic/',
                method: 'POST',
                body: dokument
            }),
            invalidatesTags: ['DokumentStatic']
        }),
        updateDocumentStatic: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/documentStatic/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['DokumentStatic']
        }),
        deleteDocumentStatic: builder.mutation({
            query: (id) => ({
                url: `/documentStatic/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['DokumentStatic']
        })
    })
});

export const { useDocumentStaticQuery, useAddDocumentStaticMutation, useUpdateDocumentStaticMutation, useDeleteDocumentStaticMutation } = documentStaticApi;
