import React from "react";
import { Cookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
/**
 *
 * @param props const isAuth = store.getState().redirect.isAuth

// if(!isAuth && window.location.pathname!='/login'){
//     window.location.href='/login'
// }
// {isAuth || (!isAuth && window.location.pathname=='/login') &&(
 * @returns
 */
const Guard = (props: { children: any }) => {
    const auth = new Cookies().get('token') ? true : false;

    const path = window.location.pathname;


    if (!auth && path !== '/login') {
      window.location.href = '/login'
    }
    if (auth || (!auth && path == '/login')) {
        return (
            <>
                {props.children}

            </>
        )
    } else {
        return (<></>)
    }

}

export default Guard;