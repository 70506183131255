import * as React from "react"

import { cn } from "../../lib/utils"
import { Label } from "./label"

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextareaWithLabel = React.forwardRef<HTMLTextAreaElement, TextareaProps & {label:string, action?: any}>(
  ({ className, ...props }, ref) => {
    return (
      <div className="gird">
        <div className="flex gap-5">
          <Label htmlFor="email" >{props?.label}</Label>
          {props?.action}
        </div>

      <textarea
        className={cn(
          "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
        />
         </div>
    )
  }
)
TextareaWithLabel.displayName = "Textarea"

export { TextareaWithLabel }
