import React from "react";
import { Card } from "../../components/ui/card";
import { InputWithLabel } from "../../components/ui/inputWithLabel";
import { Button } from "../../components/ui/button";
import { usePgQuery } from "../../services/pageGeneratorApi";
import { CheckboxWithLabel } from "@/src/components/ui/checkboxWithLabel";
import { cloneDeep, set } from "lodash";
import { UiSelect } from "../../components/ui/UiSelect";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";

import { ArrowDown, ArrowUp, Plus, Trash, AlignLeft } from "lucide-react";

import {
  useMenuQuery,
  useAddMenuMutation,
  useUpdateMenuMutation,
} from "../../services/menuApi";
import { useNavigate, useParams } from "react-router";

const menuDefault = {
  name: "",
  slug: "",
  items: [],
};

//it can be useReducer
const MenuAdd = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [menuSchema, setMenuSchema] = React.useState<any | undefined>();
  const [menu, setMenu] = React.useState<any | undefined>(undefined);
  const [addMenu, result] = useAddMenuMutation();
  const [updateMenu] = useUpdateMenuMutation();
  const { data: menuData, refetch } = useMenuQuery(params?.id, {
    skip: params?.id ? false : true,
  });

  const [categories, setCategories] = React.useState<any[] | undefined>([]);

  const addFromCategory = async () => {
    const categories = await fetch(
      "https://bizback.koniar2.usermd.net/api/public/pageSlim/category/"
    ).then((res) => res.json());
    const categoryTransform = categories.map((item) => ({
      label: item.name,
      value: item.slug,
    }));
    setCategories(categoryTransform);
  };

  React.useEffect(() => {
    if (params?.id === undefined) {
      setMenu(menuDefault);
      setMenuSchema([]);
    } else {
      setMenu(menuData?.[0] || menuDefault);
      setMenuSchema(menuData?.[0]?.items || []);
    }
    addFromCategory();
  }, [menuData, params?.id]);

  React.useEffect(() => {
    return () => {
      setMenuSchema(undefined);
      setMenu(undefined);
    };
  }, []);

  React.useEffect(() => {
    if (result.data) {
      navigate(`/menu/edit/${result.data["_id"]["$oid"]}`);
    }
  }, [result.data]);

  const addMenuItem = () => {
    const newMenu = cloneDeep(menuSchema);
    newMenu.push({
      item: "",
      link: "/",
      blank: false,
      items: [],
    });

    setMenuSchema(newMenu);
  };

  const addSubItem = (index: number) => {
    const newMenu = cloneDeep(menuSchema);
    newMenu[index].items.push({
      item: "",
      link: "/",
      blank: false,
    });
    setMenuSchema(newMenu);
  };

  const addItemCategory = (index: number, value: string) => {
    const newMenu = cloneDeep(menuSchema);
    newMenu[index].items.push({
      type: "category",
      link: value,
    });
    setMenuSchema(newMenu);
  };

  const removeItemByIndex = (index: number) => {
    const newMenu = cloneDeep(menuSchema);
    newMenu.splice(index, 1);
    setMenuSchema(newMenu);
  };

  const removeSubItemByIndex = (index: number, indexSubItem: number) => {
    const newMenu = cloneDeep(menuSchema);
    newMenu[index].items.splice(indexSubItem, 1);
    setMenuSchema(newMenu);
  };

  const moveItem = (index: number, direction: "up" | "down") => {
    const newMenu = cloneDeep(menuSchema);
    const itemToMove = newMenu[index];
    newMenu.splice(index, 1);
    if (direction === "up") {
      newMenu.splice(index - 1, 0, itemToMove);
    } else {
      newMenu.splice(index + 1, 0, itemToMove);
    }
    setMenuSchema(newMenu);
  };

  const moveSubItem = (
    index: number,
    indexSubItem: number,
    direction: "up" | "down"
  ) => {
    const newMenu = cloneDeep(menuSchema);
    const itemToMove = newMenu[index].items[indexSubItem];
    newMenu[index].items.splice(indexSubItem, 1);
    if (direction === "up") {
      newMenu[index].items.splice(indexSubItem - 1, 0, itemToMove);
    } else {
      newMenu[index].items.splice(indexSubItem + 1, 0, itemToMove);
    }
    setMenuSchema(newMenu);
  };

  const saveMenu = () => {
    const newMenu = cloneDeep(menu);

    newMenu.items = menuSchema;
    setMenu(newMenu);
    if (menu["_id"]) {
      updateMenu({ id: params.id, rest: newMenu });
    } else {
      addMenu(newMenu);
    }
  };

  const changeField = (
    field: string,
    index: number,
    subIndex?: number,
    value?: any
  ) => {
    const newMenu = cloneDeep(menuSchema);
    if (subIndex !== undefined) {
      newMenu[index].items[subIndex][field] = value;
    } else {
      newMenu[index][field] = value;
    }
    setMenuSchema(newMenu);
  };

  const setBlank = (index: number, subIndex?: number, value?: boolean) => {
    const newMenu = cloneDeep(menuSchema);
    if (subIndex !== undefined) {
      newMenu[index].items[subIndex].blank = value;
    } else {
      newMenu[index].blank = value;
    }
    setMenuSchema(newMenu);
  };

  const MenuItem = ({
    item,
    index,
    subIndex,
  }: {
    item: any;
    index: number;
    subIndex?: number;
  }) => {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 2fr 1fr",
          gap: "2em",
          marginBottom: "1em",
          marginTop: "1em",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <InputWithLabel
          label="Nazwa"
          defaultValue={item.name}
          onBlur={(e) => {
            changeField("name", index, subIndex, e.target.value);
          }}
        />
        <InputWithLabel
          label="Link"
          defaultValue={item.link}
          onBlur={(e) => {
            changeField("link", index, subIndex, e.target.value);
          }}
        />

        {/* <CheckboxWithLabel
          label={"blank"}
          checked={item?.blank || false}
          onChange={(event) => {
            setBlank(index, subIndex, event.target.checked);
          }}
        /> */}
      </div>
    );
  };

  const setMenuName = (name: string) => {
    const newMenu = cloneDeep(menu);
    newMenu.name = name;
    setMenu(newMenu);
  };

  const setMenuSlug = (slug: string) => {
    const newMenu = cloneDeep(menu);
    newMenu.slug = slug;
    setMenu(newMenu);
  };

  const findCategoryName = (slug: string) => {
    const category = categories?.find((item) => item.value === slug);
    return category?.label;
  };
  return (
    <div>
      <Card>
        {menu && (
          <div style={{ display: "flex", gap: "1em" }}>
            <InputWithLabel
              label="Nazwa menu"
              value={menu?.name || ""}
              onChange={(e) => {
                setMenuName(e.target.value);
              }}
            />
            <InputWithLabel
              label="Slug"
              value={menu?.slug || ""}
              onChange={(e) => {
                setMenuSlug(e.target.value);
              }}
            />
          </div>
        )}
        <h3 style={{ marginTop: "1em", marginBottom: "1em" }}>Elementy menu</h3>
        <div style={{ marginBottom: "1em" }}>
          {menuSchema &&
            menuSchema.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <MenuItem item={item} index={index} subIndex={undefined} />
                    <div>
                      <div className="flex">
                        <Popover>
                          <PopoverTrigger>
                            {" "}
                            <Button
                              size="sm"
                              className="mr-2"
                              aria-label="dodaj"
                            >
                              <AlignLeft size={14} />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <UiSelect
                              label="Kategoria"
                              options={categories}
                              onChange={(value) => {
                                addItemCategory(index, value);
                              }}
                            />
                          </PopoverContent>
                        </Popover>

                        <Button
                          size="sm"
                          className="mr-2"
                          aria-label="dodaj"
                          onClick={() => {
                            addSubItem(index);
                          }}
                        >
                          <Plus size={14} />
                        </Button>
                        <Button
                          size="sm"
                          className="mr-2"
                          aria-label="usuń"
                          onClick={() => {
                            removeItemByIndex(index);
                          }}
                        >
                          <Trash size={14} />
                        </Button>
                        <Button
                          size="sm"
                          className="mr-2"
                          aria-label="przesuń do góry"
                          onClick={() => {
                            moveItem(index, "up");
                          }}
                        >
                          <ArrowUp size={14} />
                        </Button>
                        <Button
                          size="sm"
                          className="mr-2"
                          aria-label="przesuń w dół"
                          onClick={() => {
                            moveItem(index, "down");
                          }}
                        >
                          <ArrowDown size={14} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "2em" }}>
                    {item.items &&
                      item.items.map((subItem, indexSubItem) => {
                        return (
                          <div
                            key={indexSubItem}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {subItem.type !== "category" && (
                              <MenuItem
                                item={subItem}
                                index={index}
                                subIndex={indexSubItem}
                              />
                            )}
                            {subItem.type === "category" && (
                              <div className="py-5">
                                Kategoria: {findCategoryName(subItem.link)}
                              </div>
                            )}
                            <div>
                              <div className="flex">
                                <Button
                                  size="sm"
                                  className="mr-2"
                                  aria-label="usuń"
                                  onClick={() => {
                                    removeSubItemByIndex(index, indexSubItem);
                                  }}
                                >
                                  <Trash size={14} />
                                </Button>
                                <Button
                                  size="sm"
                                  className="mr-2"
                                  aria-label="przesuń do góry"
                                  onClick={() => {
                                    moveSubItem(index, indexSubItem, "up");
                                  }}
                                >
                                  <ArrowUp size={14} />
                                </Button>
                                <Button
                                  size="sm"
                                  className="mr-2"
                                  aria-label="przesuń w dół"
                                  onClick={() => {
                                    moveSubItem(index, indexSubItem, "down");
                                  }}
                                >
                                  <ArrowDown size={14} />
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>

        <div
          style={{ marginTop: "1em", display: "flex", justifyContent: "end" }}
        >
          <div className="flex">
            <Button
              size="sm"
              className="mr-2"
              color="primary"
              onClick={() => {
                addMenuItem();
              }}
            >
              dodaj nową pozycje
            </Button>
            <Button
              size="sm"
              className="mr-2"
              onClick={() => {
                saveMenu();
              }}
            >
              Zapisz
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MenuAdd;
