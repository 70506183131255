import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceUrl } from "../config";
import { Cookies } from "react-cookie";

export const newConfigApi = createApi({
  reducerPath: "newConfigApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceUrl,

    prepareHeaders: (headers) => {
      const token = new Cookies().get("token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set(
        "Access-Control-Allow-Methods",
        "GET, PUT, POST, DELETE, OPTIONS"
      );
      headers.set("WithCredentials", "true");

      headers.set("Access-Control-Allow-Credentials", "true");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["NewConfig", "UnseenNewConfig"],
  endpoints: (builder) => ({
    newConfig: builder.query({
      query: () => {

        return { url: "/newConfig/", method: "GET" };

      },
      providesTags: ["NewConfig"],
    }),

    addNewConfig: builder.mutation({
      query: (page) => ({
        url: "/newConfig/",
        method: "POST",
        body: page,
      }),
      invalidatesTags: ["NewConfig"],
    }),





  }),
});

export const {
  useNewConfigQuery,

  useAddNewConfigMutation,

} = newConfigApi;
