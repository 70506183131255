import { serviceUrl } from '../config'



type ApiResponse = {
    data?: any;
    status: 'ok' | 'no'
}



export default async function upload(dataForm: FormData): Promise<ApiResponse> {
    const response = await fetch(`${serviceUrl}/upload/`, {
        method: 'POST',
        body: dataForm
    })

    const resp = await response.json()
    return resp
}


