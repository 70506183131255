import { createSlice } from '@reduxjs/toolkit';

type imagesGalleryType = {
    images: object | undefined | object[];
    dirMode: boolean;
    dirToDelete?: string;
    currentDir: string;
};
// initial state

const initialState: imagesGalleryType = {
    images: undefined,
    dirMode: false,
    dirToDelete: undefined,
    currentDir: '...'

};


// ==============================|| SLICE - MENU ||============================== //

const galleryState = createSlice({
    name: 'gallery',
    initialState,
    reducers: {


        setCurrentDir(state, action: { payload: string }) {

            state.currentDir = action.payload;
        },

        setDirToDelete(state, action: { payload: string | undefined }) {

            state.dirToDelete = action.payload;
        },

        setDirMode(state, action: { payload: boolean }) {

            state.dirMode = action.payload;
        },
        setInit(state, action?: any) {

            state.images = action.payload;

        },
        addMultipleImages(state, action?: any) {


            if (Array.isArray(state.images)) {

                state.images = [...state.images, action.payload];
            } else {

                state.images = [...[action.payload]];
            }


        },

        removeMultipleImages(state, action?: any) {
            if (Array.isArray(state.images)) {

                state.images = state.images.filter((item: any) => item._id !== action.payload._id);
            }
        },

        removeAllImages(state) {
            state.images = undefined

        },

        addSingleImage(state, action?: any) {


            state.images = action.payload;
        },
        addBachImages(state, action?: any) {


            state.images = [...action.payload];
        },
        removeSingleImage(state) {
            state.images = undefined

        }



    }
});
export default galleryState
export const { addMultipleImages, addSingleImage, setInit, removeSingleImage, removeMultipleImages, addBachImages, setDirMode, setDirToDelete, setCurrentDir, removeAllImages } = galleryState.actions;