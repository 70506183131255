import { useRef, useState, useEffect } from "react";
import GalleryGrid from "../../components/grids/galleryGrid";
import { Button } from "../../components/ui/button";
import { Card, CardHeader } from "../../components/ui/card";
import { useDeleteMediaMutation } from "../../services/mediaApi";
import {
  useAddMediaDirsMutation,
  useDeleteMediaDirsMutation,
} from "../../services/mediaDirsApi";
import { useDispatch, useSelector } from "react-redux";
import { Image as ImgComp } from "../../components/ui/image";
import {
  removeSingleImage,
  setDirMode,
  setDirToDelete,
} from "../../store/gallery";
import { Input } from "../../components/ui/input";
import upload from "../../services/uploadApi";
import { chunk } from "lodash";

import {
  Folder,
  FolderPlus,
  FolderX,
  Image,
  Trash,
  Upload,
} from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { Progress } from "../../components/ui/progress";

const Media = () => {
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const dirmode = useSelector((state: any) => state.galleryState.dirMode);
  const dirToDelete = useSelector(
    (state: any) => state.galleryState.dirToDelete
  );
  const currentDir = useSelector((state: any) => state.galleryState.currentDir);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const [showFileToDelete, setShowFileToDelete] = useState(false);
  const [openCreateDir, setOpenCreateDir] = useState(false);
  const [dirName, setDirName] = useState("");
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const selected = useSelector((state: any) => state.galleryState.images);
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const deleteSelected = () => {
    setDeleteDialog(true);
  };

  const [deleteMedia] = useDeleteMediaMutation();
  const [addDir] = useAddMediaDirsMutation();
  const [deleteDir] = useDeleteMediaDirsMutation();

  const handleCloseDialog = () => {
    setDeleteDialog(false);
    setShowFileToDelete(false);
  };

  const handleFinallyDelete = () => {
    deleteMedia(selected);
    setShowFileToDelete(false);
    setDeleteDialog(false);
    dispatch(removeSingleImage());
  };

  const handleDeleteAllFromDir = async () => {
    try {
      //@ts-ignore
      const w = await ref.current.checkAllImages();

      if (w && w.length > 0) {
        setDeleteAllDialog(false);
        deleteMedia(w);
        //@ts-ignore
        ref.current.unCheckAllImages();
      } else {
        console.log("No images to delete");
      }
    } catch (error) {
      console.error("Error deleting all images from directory:", error);
    }
  };

  const handleDeletaAllFromDirCancel = () => {
    setDeleteAllDialog(false);
    //@ts-ignore
    ref.current.unCheckAllImages();
  };

  const handleSaveNewDirectory = () => {
    addDir({ name: dirName });
    setOpenCreateDir(false);
  };

  useEffect(() => {
    if (progress === 100) {
      ref.current?.refetch();

      const t = setTimeout(() => {
        setShowProgressBar(false);
        setProgress(0);
      }, 1000);
      return () => clearTimeout(t);
    }
  }, [progress]);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    uploadFiles(event);
  };

  const uploadFiles = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const target = event?.target as HTMLInputElement;
    setShowProgressBar(true);

    if (target.files) {
      const parts = 100 / target.files.length;

      const files_chunks = chunk(Array.from(target.files), 5);

      files_chunks.reduce((prevPromise, files_chunk, i) => {
        return prevPromise.then(() => {
          const promises = files_chunk.map((file) => {
            const formData = new FormData();
            formData.append(
              "directory",
              currentDir !== "..." ? currentDir : ""
            );
            formData.append("file", file);

            return upload(formData);
          });

          return Promise.all(promises).then((resp) => {
            const prog = (i + 1) * parts * 5;
            setProgress(prog > 100 ? 100 : prog);
          });
        });
      }, Promise.resolve());
    }
  };

  return (
    <Card className="pb-[2em]">
      {showProgressBar && (
        <AlertDialog open={true}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Wgrywanie plików</AlertDialogTitle>
              <AlertDialogDescription>
                <Progress value={progress} />
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter></AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}

      <AlertDialog open={dirToDelete !== undefined}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Usuń katalog</AlertDialogTitle>
            <AlertDialogDescription>
              Uwaga! operacja nieodwracalna.
              <br />
              Zamierzasz usunąć katalog: <strong>{dirToDelete}</strong>
              <br />
              Czy chcesz kontynuować?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                dispatch(setDirToDelete(undefined));
              }}
            >
              Nie
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                deleteDir(dirToDelete);
                dispatch(setDirToDelete(undefined));
              }}
            >
              Tak
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={openCreateDir}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Utwórz katolog</AlertDialogTitle>
            <AlertDialogDescription>
              <>
                <Input
                  placeholder="Podaj nazwę katalogu - bez polskich znaków"
                  onChange={(event) => setDirName(event.target.value)}
                />
              </>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setOpenCreateDir(false);
              }}
            >
              Nie
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                handleSaveNewDirectory();
              }}
            >
              Tak
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={deleteAllDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Czy napewno chcesz skasować
              <br /> wyszystkie pliki z folderu ?
            </AlertDialogTitle>
            <AlertDialogDescription>
              <>
                Ta operacja jest nieodwracalna czy napewno chcesz kontynuować?
              </>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                handleDeletaAllFromDirCancel();
              }}
            >
              Nie
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                handleDeleteAllFromDir();
              }}
            >
              Tak
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={deleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Czy napewno chcesz skasować zaznaczone pliki
            </AlertDialogTitle>
            <AlertDialogDescription>
              <>
                Ta operacja jest nieodwracalna czy napewno chcesz kontynuować?
                <div className="flex justify-between my-[20px] items-center">
                  Ilość plików do usunięcia: {selected?.length}
                  <Button
                    variant={"ghost"}
                    onClick={() => {
                      setShowFileToDelete(!showFileToDelete);
                    }}
                  >
                    {showFileToDelete ? "Ukryj pliki" : "Pokaż pliki"}
                  </Button>
                </div>
                {showFileToDelete && (
                  <div className="flex flex-col gap-2 max-h-[100px] overflow-auto my-[10px]">
                    {selected?.map((item: any) => {
                      return (
                        <div
                          key={item._id.$oid}
                          className="flex justify-between items-center break-all bg-[#f5f5f5] p-[5px] rounded-[5px] gap-2"
                        >
                          {item.file_name} <ImgComp {...item} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCloseDialog}>
              Nie
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleFinallyDelete}>
              Tak
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="items-center gap-0 grid">
          {" "}
          <h1 className="font-bold text-2xl mb-0 pb-0">Media</h1>{" "}
          {dirmode === false && currentDir !== "..." && (
            <div className="text-sm uppercase">
              <strong>{currentDir}</strong>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center">
          {dirmode === false && (
            <TooltipProvider>
              <Tooltip delayDuration={2}>
                <TooltipTrigger>
                  {" "}
                  <Button
                    variant={"outline"}
                    onClick={() => {
                      //@ts-ignore

                      dispatch(setDirMode(!dirmode));
                    }}
                  >
                    <Folder size={"1em"} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Pokaż katalogi</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {dirmode === true && (
            <>
              <TooltipProvider>
                <Tooltip delayDuration={2}>
                  <TooltipTrigger>
                    {" "}
                    <Button
                      variant={"outline"}
                      onClick={() => {
                        //@ts-ignore
                        dispatch(setDirMode(!dirmode));
                      }}
                    >
                      <Image size={"1em"} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Pokaż media</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip delayDuration={2}>
                  <TooltipTrigger>
                    {" "}
                    <Button
                      variant={"outline"}
                      onClick={() => {
                        setOpenCreateDir(true);
                      }}
                    >
                      <FolderPlus size={"1em"} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Dodaj Folder</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}
          {dirmode === false && selected?.length > 0 && (
            <>
              <TooltipProvider>
                <Tooltip delayDuration={2}>
                  <TooltipTrigger>
                    {" "}
                    <Button
                      variant={"outline"}
                      onClick={() => {
                        deleteSelected();
                      }}
                    >
                      <Trash size={"1em"} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Skasuj zaznaczone</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}

          {dirmode === false && (
            <>
              <TooltipProvider>
                <Tooltip delayDuration={2}>
                  <TooltipTrigger>
                    {" "}
                    <Button
                      variant={"outline"}
                      onClick={() => {
                        setDeleteAllDialog(true);
                      }}
                    >
                      <FolderX size={"1em"} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Usuń wszyskie z folderu</TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip delayDuration={2}>
                  <TooltipTrigger>
                    {" "}
                    <Button
                      variant={"outline"}
                      onClick={() => {
                        uploadRef?.current && uploadRef.current.click();
                      }}
                    >
                      <input
                        type="file"
                        placeholder="image"
                        ref={uploadRef}
                        style={{ display: "none" }}
                        multiple
                        onChange={(event) => {
                          handleUpload(event);
                        }}
                      />
                      <Upload size={"1em"} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Dodaj nowe pliki</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}
        </div>
      </CardHeader>

      <GalleryGrid ref={ref} chooseMultiple={true} />
    </Card>
  );
};
export default Media;
