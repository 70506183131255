import React from 'react';
import DataTable from "../../components/pageParts/table";
import ActionCell from '../../components/pageParts/ActionCell';
import { useDeleteTurnusMutation, useTurnusQuery } from '../../services/turnusApi';
type CreatePageProps = {

}



const TurnusList = (props: CreatePageProps) => {

    const title = undefined
    const { data, refetch } = useTurnusQuery(undefined)
    const [deleteTurnus, response] = useDeleteTurnusMutation()

    React.useEffect(() => {
        if (response.isSuccess) {
            refetch()
        }
    }, [response])

    const headerCells = [
        {
            accessorKey: "objectId",
            header: "objectId",
            visibilty: false,
            fancyName: 'objectId'
        },
        {
            accessorKey: "name",
            header: "Nazwa",
            fancyName: 'objectId'
        },

        {
            accessorKey: "action",
            fancyName: 'objectId',
            header: () => { <div className='flex justify-end'>Akcje</div> },


            cell: ({ row }: any) => {

                return (<div className="justify-end flex text-[0.em]">
<ActionCell deleteAction={deleteTurnus} editLink={'turnus'} row={row} />

                </div>)
            }
        }

    ];



    const d = data?.map((el: any) => {

        return {
            objectId: el._id.$oid,
            name: el.name

        }
    })





    return (
    <div>
            {d && <DataTable columns={headerCells} data={d} />}
        </div>)

}

export default TurnusList