import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const unSeenApi = createApi({
    reducerPath: 'unSeenApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,

        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');
            headers.set('Access-Control-Allow-Origin', '*')
            headers.set("Access-Control-Allow-Methods", "GET, PUT, POST, DELETE, OPTIONS")
            headers.set("WithCredentials", "true");

            headers.set("Access-Control-Allow-Credentials", "true")

            if (token) {

             headers.set('authorization', `Bearer ${token}`)
           }
           return headers
        }
    }),
    tagTypes: ['UnSeen'],
    endpoints: (builder) => ({
        unSeen: builder.query({
            query: (arg) => {


                    return { url: '/seen/', method: 'GET' }

            },
            providesTags: ['UnSeen'],


        }),



    })
});

export const { useUnSeenQuery } = unSeenApi;
